h1, h2 ,h3, h4, h5, h6
{
	&.title-comvc
	{
		color: #000;
	}
}

p, span
{
	&.text-comvc
	{
		color: #000;
	}

	&.text-tree-description
	{
		font-size: 10px;
		padding: 0 15px;
		color: #5B422D;

		&.text-position-bottom
		{
			width: 280px;
			position: absolute;
			text-align: right;
			bottom: 15px;
			right: 15px;
			margin-bottom: 0px;
		}
	}
}