.apps-container {
  .buttons__container{
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    align-items: center;
  }
  .button__block{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0 50px 30px;
    text-decoration: none;
  }
  .button__block h2{
    color: #fff;
    font-family: 'Lato',sans-serif;
    font-weight: 700;
    font-size: 32px;
    margin-top: 10px;
    padding: 0;
    text-align: center;
    min-height: 80px;
  }
  .buttons__display{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 240px;
  }
  .button__icon{
    display: flex;
    width: 200px;
    height: 200px;
    padding: 20px;
    background-color: #fff;
    border: 8px solid #fdcd47;
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .button__icon img{
    height: auto;
    max-width: 100%;
  }
  .soon{
    position: relative;
    overflow: hidden;
  }
  .soon::before{
    content: 'EM BREVE';
    position: absolute;
    transform: rotate(-40deg);
    width: 110%;
    top: 20px;
    left: -40px;
    text-indent: 55px;
    font-family: 'Lato',sans-serif;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    height: 40px;
    background-color: #006766;
    display: flex;
    align-items: center;
    z-index: 2;
  }
  .soon::after{
    content: '';
    position: absolute;
    left: 2px;
    background-color: #004948;
    height: 20px;
    width: 30px;
    top: 118px;
    z-index: 0;
    border-bottom-left-radius: 5px;
  }
  @media(min-width:1921px){
    .buttons__display{
        display: grid;
        grid-template-columns: repeat(4, 190px);
        padding-top: 190px;
    }
    .buttons__display-alt{
      display: flex;
      justify-content: center;
    }
    .button__block{
        margin: 0 10px 20px;
    }
    .button__block:nth-child(5){
        margin: 0 10px 0;
        grid-column-start: 2;
    }
    .button__block:nth-child(6){
        margin: 0 10px 0;
    }
    .button__block h2{
        font-size: 22px;
        min-height: 40px;
    }
    .button__icon{
        width: 150px;
        height: 150px;
    }
    .soon::after{
        content: '';
        position: absolute;
        left: 1px;
        background-color: #004948;
        height: 20px;
        width: 30px;
        top:95px;
        z-index: 0;
        border-bottom-left-radius: 5px;
    }
  }
  @media(max-width:1920px){
    .buttons__display{
        display: grid;
        grid-template-columns: repeat(4, 190px);
        padding-top: 190px;
    }
    .buttons__display-alt{
      display: grid;
      grid-template-columns: repeat(3, 190px);
      justify-content: center;
    }
    .button__block{
        margin: 0 10px 20px;
    }
    .button__block:nth-child(5){
        margin: 0 10px 0;
        grid-column-start: 2;
    }
    .button__block:nth-child(6){
        margin: 0 10px 0;
    }
    .button__block h2{
        font-size: 22px;
        min-height: 40px;
    }
    .button__icon{
        width: 120px;
        height: 120px;
    }
    .soon::before{
        content: 'EM BREVE';
        position: absolute;
        transform: rotate(-40deg);
        width: 110%;
        top: 20px;
        left: -40px;
        text-indent: 50px;
        font-family: 'Lato',sans-serif;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        height: 30px;
        background-color: #006766;
        display: flex;
        align-items: center;
        z-index: 2;
    }
    .soon::after {
        content: '';
        position: absolute;
        left: -2px;
        background-color: #004948;
        height: 23px;
        width: 42px;
        top: 77px;
        z-index: 0;
        border-bottom-left-radius: 5px;
    }
  }
  @media(max-width:978px){
    .buttons__container{
        background-size: cover;
        background-repeat: no-repeat;
        /* background-position: center; */
        height: 100%;
        display: flex;
        align-items: center;
        background-position: -590px 0px;
        overflow: visible;
    }
    .buttons__display{
        display: grid;
        grid-template-columns: repeat(2, 190px);
        padding-top: 310px;
        
    }
    .buttons__display-alt{
      display: grid;
      grid-template-columns: repeat(2, 190px);
      padding-top: 0;
      padding-left: 0;
    }
    .button__block:nth-child(5){
        grid-column-start: 1;
    }
    .button__block h2{
        font-size: 18px;
        min-height: 50px;
    }
  }
}
