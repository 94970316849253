.faq {
    margin: 30px auto 120px;

    .title {
        margin-bottom: 30px;
    }
}

.faq-list {
    position: relative;

    .panel-title {
        position: relative;
        cursor: pointer;
        z-index: 10;
    }

    &.active {
        .panel-body {
            max-height: 500px;
            opacity: 1;
        }

        .icon-collapse {
            transform: rotate(90deg);
        }
    }

    .faq-answer {
        padding: 20px;
        word-wrap: break-word;
    }

    .icon-collapse {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 18px;

        transition: all .2s;
    }

    .panel-body {
        max-height: 0;
        opacity: 0;
        padding: 0;
        transition: all .6s;
        overflow: hidden;
    }
}