*
{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
    font-size: 100%;
	-webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

html,
body
{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	position: relative;
}

a
{
	text-decoration: none;
}

ul
{
	list-style: none;
}

textarea,
input,
select,
button
{
	outline: none;
}

select
{
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

p
{
	font-size: 1em;
}

.clear-fix
{
	&:before
	{
		@include clearfix;
	}
	&:after
	{
		@include clearfix;
	}
}