@font-face {
	font-family: 'icomoon';
	src:  url('../../fonts/icomoon.eot?jvd85u');
	src:  url('../../fonts/icomoon.eot?jvd85u#iefix') format('embedded-opentype'),
	  url('../../fonts/icomoon.ttf?jvd85u') format('truetype'),
	  url('../../fonts/icomoon.woff?jvd85u') format('woff'),
	  url('../../fonts/icomoon.svg?jvd85u#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
  }
  
  [class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  .icon-pause:before {
	content: "\e909";
  }
  .icon-star-full:before {
	content: "\e906";
  }
  .icon-star-empty:before {
	content: "\e907";
  }
  .icon-pause-bar:before {
	content: "\e908";
  }
  .icon-audio:before {
	content: "\e900";
  }
  .icon-next-podcast:before {
	content: "\e901";
  }
  .icon-play-bar:before {
	content: "\e902";
  }
  .icon-play:before {
	content: "\e903";
  }
  .icon-prev-podcast:before {
	content: "\e904";
  }
  .icon-volume:before {
	content: "\e905";
  }
  