.button-step
{
    border-radius: 13px;
    padding: 3px 10px;
    color: #FFF;
    background-color: #194C38;
    display: inline-block;
}

.step-leaf-represent
{
    background-image: url('../../images/leaf-routes.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    flex: 0 0 30px;
    height: 31px;
    display: inline-block;
    margin-right: 12px;

    &.specialist
    {
        background-image: url('../../images/leaf-specialist.svg');
    }

    &.director
    {
        background-image: url('../../images/leaf-director.svg');
    }

    &.manager
    {
        background-image: url('../../images/leaf-manager.svg');
    }

    &.encharge
    {
        background-image: url('../../images/leaf-encharge.svg');
    }

    &.supervisor
    {
        background-image: url('../../images/leaf-supervisor.svg');
    }
}

.step-info-holder
{
    flex: 1 1 calc(100% - 42px);
    max-width: calc(100% - 42px);

    .title
    {
        font-size: 21px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 800;
        margin: 0;
        line-height: 32px;
        color: #D5454A;
    }

    .label-step
    {
        color: #5B422D;
        font-size: 12px;
        font-weight: 300;
        line-height: 17px;
        display: block;
        margin-bottom: -3px;
    }

    .jobtitles-number
    {
        color: #999;
        line-height: 14px;
        font-size: 10px;
        margin-bottom: 5px;
        display: block;
    }
    
    .more-info
    {
        color: #D5454A;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        display: block;
        margin-top: -3px;
    }
}

.level-step-item
{
    margin-bottom: 35px;
    display: block;

    .current-jobtitle
    {
        display: block;
        color: #5B422D;
        line-height: 17px;
        font-size: 12px;
        font-weight: 300;
    }

    .title
    {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 0px;
    }

    .see-jobtitle
    {
        color: #4A4A4A;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
    }

    &.current
    {
        .title
        {
            font-weight: 700;
            margin: 6px 0;
        }
    }

    &:last-child
    {
        margin-bottom: 0px;
    }
}

.step-item
{
    display: flex;
    position: relative;

    &:before
    {
        content: '';
        display: block;
        width: 19px;
        height: 45px;
        background-image: url('../../images/step-before.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        bottom: -30px;
        left: -19px;
    }

    &.general-step-item
    {
        &:before
        {
            bottom: auto;
            left: -18px;
            top: 36px;
        }

        .step-info-holder
        {
            .title
            {
                display: block;
                font-weight: 400;
                margin-bottom: 12px;
                line-height: 34px;
                font-size: 18px;
            }

            .jobtitles-list
            {
                display: flex;
                flex-wrap: wrap;

                .jobtitle-item
                {
                    margin-bottom: 5px;
                    
                    > .button-step
                    {
                        font-size: 10px;
                    }

                    > .relation-holder
                    {
                        margin: 0 5px 0 3px;
                        position: relative;
                        font-size: 10px;
                    }
                }
            }
        }
    }

    &.goal-step-item
    {
        align-items: center;
        margin-bottom: 30px;
    }

    &.my-own-step-item
    {
        margin-top: 30px;

        &:before
        {
            bottom: -51px;
            left: -14px;
        }

        .step-leaf-represent
        {
            background-image: url('../../images/leaf-my-jobtitle.svg');
            flex: 0 0 44px;
            max-width: 44px;
            height: 45px;
        }
        
        .step-info-holder
        {
            flex: 0 0 calc(100% - 56px);
            max-width: calc(100% - 56px);

            .title
            {
                color: #076640;
            }
        }
    }

    &.routes-track-step-item
    {
        .step-leaf-represent
        {
            flex: 0 0 39px;
            max-width: 39px;
            height: 32px;
        }
        
        .step-info-holder
        {
            flex: 0 0 calc(100% - 51px);
            max-width: calc(100% - 51px);

            .title
            {
                color: #194C38;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }

            .label-step
            {
                color: #999;
                font-size: 10px;
                line-height: 14px;
                margin-bottom: 5px;
                font-weight: 400;
                display: block;
            }
        }

        &:before
        {
            bottom: -12px;
        }

        &:nth-child(odd)
        {
            justify-content: flex-end;

            &:before
            {
                left: auto;
                right: -9px;
                transform: rotateY(180deg);
            }

            .step-leaf-represent
            {
                order: 2;
                margin-left: 12px;
                margin-right: 0px;
            }

            .step-info-holder
            {
                order: 1;
                text-align: right;
            }
        }
    }

    &.my-track-step-item
    {
        .step-leaf-represent
        {
            flex: 0 0 34px;
            max-width: 34px;
            height: 34px;
        }

        .step-info-holder
        {
            .title
            {
                margin-bottom: 0px;
            }
        }
    }

    &.my-own-step-level
    {
        align-items: center;

        &:before
        {
            top: 80px;
            left: -18px;
        }

        .step-leaf-represent
        {
            flex: 0 0 45px;
            max-width: 45px;
            height: 45px;
        }

        .step-info-holder
        {
            .title
            {
                margin-bottom: 0px;
                font-weight: 800;
            }
        }
    }

    @media screen
    {
        @media (min-width: 410px)
        {
            .step-info-holder
            {
                .title
                {
                    font-size: 23px !important;
                }
            }

            &.my-own-step-item
            {
                &:before
                {
                    bottom: -49px;
                }
            }

            &.routes-track-step-item
            {   
                .step-info-holder
                {
                    .title
                    {
                        font-size: 18px;
                    }
                }
            }
        }

        @media (min-width: 768px)
        {
            &.my-own-step-level
            {
                &:before
                {
                    left: -18px;
                }
            }
        }
    }
}

.goal-holder-text
{
    margin-bottom: 30px;

    > .goal-step-item
    {
        margin-bottom: 10px;
    }

    > p
    {
        color: #666;
        font-size: 12px;

        span
        {
            height: 18px;
            border-radius: 5px;
            display: inline-block;
            text-align: center;
            color: #FFF;
            font-size: 12px;
            line-height: 18px;
            margin: 0 2px;
            padding: 0 6px;
        }
    }

    @media screen
    {
        @media (min-width: 768px)
        {
            > p
            {
            }
        }
    }
}