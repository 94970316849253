.footer
{
    .container
    {
         > div
         {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;

            &.footer-alt-logo
            {
                margin-bottom: 30px;

                .logo
                {
                    &.logo-hotifruti{
                        max-width: 70px;
                        img{
                            max-width: 100%;
                        }
                    }
                    display: inline-block;

                    &:not(:last-child)
                    {
                        margin-right: 30px;
                    }
                }
            }
			a {
				color: white;
			}
         }
    }

    @media screen and (min-width: 768px)
    {
        padding: 60px 0;

        .container
        {
            justify-content: space-around;
            position: relative;

            > div
            {
                &.footer-main-logo
                {
                    flex: 0 0 170px;
                    max-width: 170px;
                    position: absolute;
                }

                &.footer-alt-logo
                {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin: 0 auto;
                    justify-content: space-around;
                    display: flex;

                    .logo
                    {
						max-height: 70px;
                        &:not(:last-child)
                        {
                            margin-right: 0px;
                        }
                    }
                }
                
                img
                {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}