@keyframes appearLogo {
    0% {opacity: 0; transform: scale(0)}
    95% {opacity: 0.9; transform: scale(1.07)}
    100% {opacity: 1; transform: scale(1)}
}

.body-content-holder
{
    padding: 95px 0 0 55px;
    height: 100%;
    overflow: hidden;

    .video-loading
    {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 1;
        transition: opacity .6s ease-in-out;
        pointer-events: none;
        z-index: 20;
        background-color: #eee6d8;

        .logo-holder
        {
            background-image: url('../../images/logo-loading.png');
            background-repeat: no-repeat;
            background-position: center;
            width: 204px;
            height: 84px;
            position: absolute;
            top: 74px;
            left: 0;
            right: 0;
            margin: 0 auto;
            opacity: 0;
            animation: appearLogo .6s linear 4.1s forwards;
        }

        video
        {
            position: absolute;
            bottom: 21px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .route-content-holder
    {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 45px);
        padding-right: 45px;
        opacity: 0;
        transition: opacity .45s ease-in-out;
        padding-bottom: 60px;
        
        > .goal-step-item
        {
            flex: 0 0 65px;
            max-height: 65px;
        }

        > .my-own-step-item
        {
            flex: 0 0 auto;
            max-height: 100%;
        }

        .route-careers-holder
        {
            flex: 1 1 auto;
            min-height: 0;
            width: 100%;
            position: relative;
            display: flex;

            &:before
            {
                content: '';
                height: 60px;
                width: 100%;
                position: absolute;
                top: -1px;
                right: 0;
                left: -18px;
                background: -moz-linear-gradient(top, rgba(252,251,248,1) 0%, rgba(250,249,245,0.5) 50%, rgba(255,255,255,0) 100%);
                background: -webkit-linear-gradient(top, rgba(252,251,248,1) 0%,rgba(250,249,245,0.5) 50%,rgba(255,255,255,0) 100%);
                background: linear-gradient(to bottom, rgba(252,251,248,1) 0%,rgba(250,249,245,0.5) 50%,rgba(255,255,255,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcfbf8', endColorstr='#00ffffff',GradientType=0 );
                z-index: 1;
                opacity: 0;
                transition: opacity .3s ease-in-out;
                pointer-events: none;
            }

            > .scroll-div-career-route
            {
                width: 100%;
                flex: 1 1 100%;
                min-height: 0;
                max-height: 100%;
                overflow: auto;
                margin: 0 -55px;
                padding: 0 43px 0 55px;
            }

            > .div-career-route
            {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;

                .small-tree-holder
                {
                    background-image: url('../../images/tree-small.svg');
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 87px;
                    height: 133px;
                    margin: 0 auto 10px;
                }

                .text-holder
                {
                    text-align: center;
                    font-size: 12px;
                    line-height: 19px;
                    color: #076640;
                    
                    b
                    {
                        display: block;
                        font-size: 14px;
                    }
                }
            }

            .step-item
            {
                flex: 0 0 auto;
                max-width: 100%;
                margin-bottom: 30px;
            }

            .routes-track-step-item
            {
                flex: 0 0 67px;
                max-height: 67px;
                margin-bottom: 15px;
            }

            &.scroll-gradient
            {
                &:before
                {
                    opacity: 1;
                }
            }

            &.scroll-bottom-gradient
            {
                &:before
                {
                    opacity: 1;
                    transform: rotate(180deg);
                    top: auto;
                    bottom: -1px;
                    background: -moz-linear-gradient(top, rgb(243, 239, 225) 0%, rgba(250,249,245,0.5) 50%, rgba(241, 236, 221, 0) 100%);
                    background: -webkit-linear-gradient(top, rgb(243, 239, 225) 0%,rgba(250,249,245,0.5) 50%,rgba(241, 236, 221, 0) 100%);
                    background: linear-gradient(to bottom, rgb(243, 239, 225) 0%,rgba(250,249,245,0.5) 50%,rgba(241, 236, 221, 0) 100%);
                }
            }
        }

        .level-content-holder
        {
            padding-left: 8px;
            width: 100%;
            height: 100%;
            overflow: hidden;

            > .scroll-area
            {
                width: 100%;
                height: 100%;
                overflow-y: scroll; 
            }
        }

        .jobtitle-content-holder
        {
            flex: 0 0 100%;
            max-height: 100%;
            padding-bottom: 25px;
            width: 100%;
            overflow: visible;
            margin: 0 -17px 0 -55px;

            .scroll-area
            {
                width: 100%;
                height: 100%;
                padding: 0 17px 0 55px;
                box-sizing: content-box;
                overflow-y: scroll;
            }
        }

        &.route-entry-content-holder
        {
            padding-right: 15px;

            &:after
            {
                display: none;
            }

            > .general-step-item
            {
                flex: 0 0 auto;
                max-height: 100%;
                margin-bottom: 30px;
            }

            .greetings
            {
                right: 15px;
            }

            .route-careers-holder
            {
                > .scroll-div-career-route
                {
                    padding-right: 15px;
                }
            }
        }

        &.route-loaded
        {
            opacity: 1;
        }

        &.route-alternative-content-holder
        {
            .route-careers-holder
            {
                .scroll-div-career-route
                {
                    .step-item
                    {
                        .jobtitles-list
                        {
                            .jobtitle-item:not(:last-child)
                            {
                                margin-right: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    .greetings
    {
        color: #076640;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 0px;
        position: absolute;
        right: 15px;
        top: 30px;

        &.front-greetings
        {
            opacity: 0;
            transition: opacity .45s ease-in-out;

            &.greetings-show
            {
                opacity: 1;
            }
        }

        &.all-routes
        {
            max-width: 200px;
            text-align: right;

            > i
            {
                &.unrotate-icon
                {
                    transform: rotate(0deg);
                    margin-left: 7px;
                }
            }
        }

        i
        {
            display: inline-block;
            transform: rotate(180deg);
            position: relative;
            right: 2px;
            font-size: 18px;
            top: 2px;
        }
    }

    &.body-content-loaded
    {
        .video-loading
        {
            opacity: 0;
            z-index: -1;
        }
    }

    @media screen
    {
        @media (max-width: 410px) {
            .greetings
            {
                font-size: 10px;

                i
                {
                    font-size: 14px;
                }
            }
        }

        @media (min-width: 768px)
        {
            .route-content-holder
            {
                padding-bottom: 30px;

                .route-careers-holder
                {
                    > .div-career-route
                    {
                        .small-tree-holder
                        {
                            width: 117px;
                            height: 183px;
                            margin: 0 auto 15px;
                        }

                        .text-holder
                        {
                            font-size: 14px;
                            
                            b
                            {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }

        @media (min-width: 1024px)
        {
            &:after
            {
                content: '';
                width: 650px;
                height: 825px;
                display: block;
                background-image: url(../../images/tree-background.png);
                background-repeat: no-repeat;
                background-position: bottom right;
                background-size: contain;
                position: fixed;
                bottom: -50px;
                right: 50px;
                z-index: 0;
                pointer-events: none;
            }

            .route-content-holder
            {
                padding-bottom: 0px;
            }
        }
    }
}