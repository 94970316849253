.panel-comvc
{
	border: 1px solid #e5e5e5;
	padding: 20px;
	border-radius: 3px;
	background: #FFF;
	.colmn-left
	{
		float: left;
	}
	.colmn-right
	{
		float: right;
	}

	&.expirate-panel
	{
		.expirate-date
		{
			text-align: center;
			margin-bottom: 30px;
			.info-circle
			{
				margin-right: 10px;
				background: #FF1921;
				border-radius: 50%;
				color: #FFF;
				border: 3px solid #000;
				width: 41px;
				margin: 0 auto 10px;
				height: 41px;
				font-size: 23px;
				text-align: center;
				line-height: 38px;
				span
				{
					color: #FFF;
				}
			}
			.expirate-info
			{
				.expirate-title
				{
					margin: 0;
					font-size: 17px;
					color: #205751;
				}
				.expirate-descr
				{
					font-size: 14px;
					color: #2B8076;
				}
			}
		}

		.renewal-now
		{
			text-align: center;
			width: 100%;
		}	
	}

	&.panel-danger
	{
		border: 1px solid #FF1921;
	}
}

@media screen and (min-width: 768px) {
	.panel-comvc
	{
		&.expirate-panel
		{
			.expirate-date
			{
				text-align: left;
				margin-bottom: 0;
				.info-circle
				{
					margin: 0;
					margin-right: 10px;
					float: left;
				}
				.expirate-info
				{
					float: left;
					margin-top: -1px;
					.expirate-title
					{
						font-size: 20px;
						font-weight: 400;
					}
					.expirate-descr
					{
						font-size: 14px;
					}
				}
			}
			.renewal-now
			{
				margin-top: 3px;
				width: auto;
			}
		}
	}
}