.header-wrapper
{
    .logo-holder
    {
        font-size: 0px;
        margin: 0px;
        padding: 23px 0 0;
        position: absolute;
        left: 55px;
        z-index: 18;
        
        .logo
        {
            display: inline-block;
            text-indent: -9999px;
            width: 94px;
            height: 39px;
            background-size: cover;
            background-position: center;
            background-position: center;
            background-image: url('../../images/logo-career-track.png');
        }

        &.logo-over-side
        {   
            .logo
            {
                background-image: url('../../images/logo-career-track-white.png');
            }
        }
    }
}