* {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    box-sizing: border-box;
}

.owl-item a {
    margin: 0 !important;
}

.form-control:focus {
    border-color: #e0221f;
}

.cover-header {
    background-color: #fafafa;
}

.expiry-content {
    margin-bottom: -30px
}

.reactive-link:hover {
    text-decoration: underline;
}

.ui-droppable-hover
{
    background-color: #ddd!important;
}

.form-ead {
    label {
        color: #ed1c24;
    }
}

#contact-submit.disabled {
    cursor: wait;
}

.btn
{
    border: 1px solid #fff;

    touch-action: none;
    &.btn-comvc
    {
        background: #ff001e;
        color: #FFF;
    }
}
a
{
    cursor: pointer;
}
.btn-disabled-modal
{
    color: #758697;
    background: #e8e8e8;
}
.btn-disabled
{
    background: #8bc34a;
}
.modal .modal-content .modal-header .modal-info .panel-title
{
    font-size: 13px;
    font-weight: 600;

    color: #2b425b;
}
.text-success
{
    color: #8bc34a !important;
}
.text-warning
{
    color: #ff9902 !important;
}
.text-danger
{
    color: #e0221f !important;
}
.body-single-course
{
    background-color: #000;
}

.answered,
.answered .item-connect
{
    border-color: #8bc34a !important;
}

.answered
{
    border-right: 0 !important;
    border-radius: 3px 0 0 3px !important;
    .connect-aux
    {
        display: none;
    }
    .answer-connect
    {
        border-left: none;
        border-radius: 0 3px 3px 0;
    }
}

.text
{
    color: #2b425b;
}
.btn.disabled,
.btn[disabled]
{
    opacity: .2;
}
.badge
{
    font-size: 13px;
    font-weight: normal;
    line-height: 14px;

    width: 21px;
    height: 21px;
    padding: 3px 0;

    text-align: center;

    border-radius: 50%;
}
.panel-site .panel-title
{
    color: #2b425b;
}

.info-user-holder
{
    width: auto;
}

.panel
{
    border-radius: 3px !important;
}

.panel-body
{
    padding: 20px;
}

.panel-body-responsive
{
    height: calc(100% - 65px);
}

.btn
{
    border-radius: 3px !important;
}

.list-group-item.vertical {
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-embed {
        margin-left: 10px;
    }
}

.info-user-holder .panel
{
    padding: 5px 20px;

    background: #e0221f;
}

.info-user-holder .panel .name-user
{
    color: #fff;
}

.info-user-holder > div > div > span
{
    padding: 15px;

    border: 1px solid #fff;
    background: url('../../images/8.png') no-repeat;
    background-size: cover;
}

.img-course
{
    position: relative;

    display: block;

    width: 100%;
    height: 194px;
    margin-bottom: 30px;

    border-radius: 5px;
    background: url('../../images/card-img.png') no-repeat;
    background-size: cover;
}

.panel-info-course > .panel-body
{
    position: relative;

    padding: 0;
}
.panel-info-course > .panel-body .descr-course
{
    margin-bottom: 20px;
    padding: 15px 20px 15px;

    word-break: break-word;

    border-bottom: 1px solid #e7ecf3;
}
.panel-info-course > .panel-body .descr-course.descr-course-responsive
{
    overflow-y: auto;
    max-height: 100%;
}
.panel-info-course > .panel-body .info-course
{
    padding: 0 20px 25px;
}
.panel-info-course > .panel-body .info-course > p
{
    margin-bottom: 20px;

    color: #2b425b;
}
.panel-info-course > .panel-body .info-course > p:last-child
{
    margin-bottom: 0;
}
.panel-info-course > .panel-body .info-course > p i
{
    color: #000;
}


.pre-test-holder
{
    clear: both;
}
.pre-test-holder,
.final-evaluation-holder,
.item-course-holder
{
    margin-bottom: 15px;
}
.panel-icon
{
    position: relative;
}
.panel .btn > i
{
    margin-right: 9px;

    vertical-align: middle;
}
.panel-icon .icon-holder
{
    line-height: 24px;

    position: absolute;
    z-index: 2;
    right: 0;
    bottom: -25px;
    left: 0;

    display: block;

    width: 51px;
    height: 25px;
    margin: 0 auto;

    text-align: center;

    border: 1px solid #8bc34a;
    border-top: none;
    border-radius: 0 0 25px 25px;
    background: #fff;
}
.panel-icon:before
{
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: -2px;
    left: 0;

    display: block;

    width: 49px;
    height: 3px;
    margin: 0 auto;

    content: '';

    background: #fff;
}
.last-panel:before
{
    display: none;
}
.panel-icon .icon-holder-danger
{
    border: 1px solid #e0221f;
}
.panel-icon .warning-icon
{
    border-color: #ffa726;
}
.panel-icon .warning-icon i
{
    color: #ffa726;
}
.panel-pre-test .btn
{
    margin-top: 30px;
}

.success-icon > i
{
    color: #8bc34a;
}

.panel-icon .danger-icon
{
    border: 1px solid #e0221f;
}

.panel-item-course .panel-title
{
    padding: 0;
}
.panel-item-course .img-course
{
    margin-bottom: 5px;
}
.panel-item-course .info-course > p
{
    margin-bottom: 40px;
}
.img-course .progress
{
    position: absolute;
    bottom: 0;

    width: 99.9%;
    height: 8px;
    margin-bottom: 0;

    border-radius: 0 0 5px 5px;
}

.panel-opacity .panel-title,
.panel-opacity .text-default,
.panel-opacity .btn-disabled
{
    opacity: .5;
}

.panel-opacity > .panel-body > .text-default
{
    margin-bottom: 40px;
}

//Modals Edit Style
.text-mar-btm
{
    margin-bottom: 20px;
}
.modal-info
{
    margin-top: 15px;
}
.text-close-modal
{
    display: inline-block;

    margin-bottom: 15px;
}
textarea.question-text
{
    height: 190px;
}

.question-text
{
    border: 1px solid #758697;
    border-radius: 3px;
}

.flexbox-area
{
    display: flex;

    justify-content: space-between;
}

.progress-exercise
{
    line-height: 30px;
}
.progress-exercise > p
{
    margin-bottom: 0;
}


.answers-item
{
    display: block;

    width: 100%;
    margin-bottom: 20px;
    padding: 10px;

    transition: all .4s ease-in-out;

    border: 1px solid #758697;
    border-radius: 3px;    
}

.drop-item
{
    position: relative;

    display: inline-block;

    width: 138px;
    height: 20px;
    margin: 0 5px;

    transition: all .4s ease-in-out;
    text-align: center;
    //top: 5px;
    vertical-align: middle;

    border: 1px dashed #758697;
    border-radius: 3px;
}
.drop-item-complete
{
    width: auto;
    height: auto;
    margin: 0;

    border: 0;
    border-radius: 0;
}

.drag-holder
{
    height: auto;
    min-height: 40px;
    margin-top: 35px;

    text-align: center;
}
.drag-holder > .drag-item
{
    display: block;

    margin: 0 5px 20px 5px;
    padding: 10px;

    color: #2b425b;
    border: 1px solid #758697;
    border-radius: 3px;
}
body.course-page {
    background: #000;
}
.item-connect
{
    position: relative;

    margin-bottom: 30px;
    padding: 10px;

    cursor: pointer;
    transition: all .3s ease-in-out;

    border: 1px solid #758697;
    border-radius: 3px;
}
.question-connect
{
    padding-bottom: 30px;
}

.connect-aux
{
    display: inline-block;

    width: 40px;
    height: 20px;

    border: 1px solid #758697;
}
.connect-bot
{
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: -1px;
    left: 0;

    margin: 0 auto;

    border-bottom: 0;
    border-radius: 25px 25px 0 0;
}
.connect-top
{
    position: absolute;
    z-index: 3;
    top: -20px;
    right: 0;
    left: 0;

    margin: 0 auto;

    border-bottom: 0;
    border-radius: 25px 25px 0 0;
}
.white-space
{
    position: absolute;
    bottom: -1px;

    display: block;

    width: 38px;
    height: 4px;

    background: #fff;
}
.title-area-exercise
{
    margin-bottom: 30px;
}
.question-simple {

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input {
        //outline: none;
    }

    a {
        position: relative;
        display: block;
    }
    .question-number{
        line-height: 40px;
    }
    .question-number, .answer {
        width: 30px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        font-size: 24px;
    }

    .answer {
        //left: -20px;
        left: -7px;
        margin-right: 5px;
        border: 1px solid;
        border-radius: 3px;
    }
}

.answer-connect {
    //margin-left: 15px;
    margin-left: 30px;
}

.your-answer {
    padding: 20px;
    border-right: 1px solid #eeeeee;
}

.y-answer-title {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: bold;
}

.feedback {
    padding: 20px;
    padding-left: 20px!important;
}

.feedback-response {
    font-size: 28px;
    margin-bottom: 10px;
    text-align: center;
}

.question-connect-active
{
    padding-bottom: 0;
}
.answer-connect-inactive
{
    display: none;
}
.question-connect-active .answer-connect
{
    margin: 20px 0;
}
.question-connect-active .connect-aux.connect-bot
{
    display: none;
}
.question-connect-active .answer-connect .connect-aux.connect-top
{
    display: none;
}

.modal
{
    overflow-y: scroll;
}
.body-single-course .header-holder .logo-holder
{
    float: left;
}
.body-single-course .header-holder .exit-holder
{
    float: right;

    margin-top: 10px;
}
.body-single-course .header-holder .logo-holder .title-logo
{
    margin-top: 0;
}
.ead-page .header-holder
{
    background-color: #EAE2D8;
}
.header-holder .header-elements .logo-holder 
{
    display: inline-block;
}
.header-holder .header-elements 
{
    padding-bottom: 20px;
}
.header-holder .header-elements .top-bar
{
    margin: 10px -15px 30px;
    height: 43px;
    margin-bottom: 0;
}
.ead-page .header-holder .name-holder .text
{
    color: #5B422E;
}
.ead-page .header-holder .name-holder .text-green
{
    color: #30ad63;
}
.header-holder .menu-area {
    margin: 0 auto;
    background: #E5E5E5;
    padding: 10px 0;
    padding-top: 30px;
}

.header-holder .menu-area .menu-item:first-child{
    //padding-right: 10px;
}

.body-single-course .header-holder .logo-holder .title-logo .logo
{
    display: inline-block;

    width: 140px;
    height: 40px;

    text-indent: -9999px;

    background: url('../../images/logo.png') no-repeat;
    background-size: cover;
}
.body-single-course .header-holder .logo-holder .title-logo .logo.logo-alt
{
    background: url('../../images/logo-normal.png') no-repeat;
    background-size: contain;
}
.back-index
{
    font-size: 16px;

    color: #fff;
}
.back-index > i
{
    font-size: 22px;

    vertical-align: sub;
}
.panel-accordion
{
    margin-bottom: 0;
}
.panel-accordion:last-child
{
    margin-bottom: 0;
}
.icon-accordion > i
{
    font-size: 22px;
}
.gradient-circle
{
    float: left;
}
.question-connect.active
{
    border: 1px solid #8bc34a;
}
.circle-warning .icon-warning
{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff9902+0,f2d000+100 */
    background: rgb(255,153,2); /* Old browsers */
    background:    -moz-linear-gradient(top,  rgba(255,153,2,1) 0%, rgba(242,208,0,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,153,2,1) 0%,rgba(242,208,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background:         linear-gradient(to bottom,  rgba(255,153,2,1) 0%,rgba(242,208,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9902', endColorstr='#f2d000',GradientType=0 ); /* IE6-9 */
    -webkit-text-fill-color: transparent;
}
.circle-success .icon-success
{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8bc34a+0,d5ff00+100 */
    background: rgb(139,195,74); /* Old browsers */
    background:    -moz-linear-gradient(top,  rgba(139,195,74,1) 0%, rgba(213,255,0,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(139,195,74,1) 0%,rgba(213,255,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background:         linear-gradient(to bottom,  rgba(139,195,74,1) 0%,rgba(213,255,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bc34a', endColorstr='#d5ff00',GradientType=0 ); /* IE6-9 */
    -webkit-text-fill-color: transparent;
}
.icon-purple > i
{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ef5350+0,ff0077+100 */
    background: rgb(239,83,80); /* Old browsers */
    background:    -moz-linear-gradient(top,  rgba(239,83,80,1) 0%, rgba(255,0,119,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(239,83,80,1) 0%,rgba(255,0,119,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background:         linear-gradient(to bottom,  rgba(239,83,80,1) 0%,rgba(255,0,119,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef5350', endColorstr='#ff0077',GradientType=0 ); /* IE6-9 */
    -webkit-text-fill-color: transparent;
}
.icon-primary > i
{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#30c3fb+0,8400ff+100 */
    background: rgb(48,195,251); /* Old browsers */
    background:    -moz-linear-gradient(top,  rgba(48,195,251,1) 0%, rgba(132,0,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(48,195,251,1) 0%,rgba(132,0,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background:         linear-gradient(to bottom,  rgba(48,195,251,1) 0%,rgba(132,0,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30c3fb', endColorstr='#8400ff',GradientType=0 ); /* IE6-9 */
    -webkit-text-fill-color: transparent;
}
.icon-caps > i
{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ab46bc+0,ff008d+100 */
    background: rgb(171,70,188); /* Old browsers */
    background:    -moz-linear-gradient(top,  rgba(171,70,188,1) 0%, rgba(255,0,141,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(171,70,188,1) 0%,rgba(255,0,141,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background:         linear-gradient(to bottom,  rgba(171,70,188,1) 0%,rgba(255,0,141,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ab46bc', endColorstr='#ff008d',GradientType=0 ); /* IE6-9 */
    -webkit-text-fill-color: transparent;
}
.panel-group > div + div > .panel
{
    margin-left: 0;
}
.text-alert
{
    color: #ff9902;
}
.panel.collapse
{
    border: 0;
}
.text-hiper
{
    color: #e0221f;
}
.text-files
{
    color: #1690f3;
}
.text-caps
{
    color: #ab46bc;
}
.panel-accordion .panel-heading .text-center .accordion-active
{
    background: #fff;
}
.accordion-item > span
{
    line-height: 29px;

    float: left;

    margin-left: 20px;
}
.panel-accordion .panel-heading .panel-title > a
{
    width: 100%;
    height: 50px;
    padding: 10px 15px;
}
.panel-accordion > .panel
{
    border-radius: 0 0 5px 5px !important;
}
.video-holder > iframe
{
    width: 100%;
    height: auto;
}
.video-holder > iframe.scorm-course {
    height: 220px;
}
.panel-accordion .panel .panel-body
{
    padding-top: 5px;
}
.panel-accordion .panel .panel-body .panel-heading
{
    margin: 0 -20px 20px;
    padding: 0 20px;

    border-bottom: 1px solid rgba(0, 0, 0, .07);
}
.panel-accordion .panel .panel-body .text-panel
{
    margin-bottom: 20px;
}
.holder-control
{
    display: block;

    margin-top: 0;
}
.position-panel .panel-body .text-right
{
    text-align: start;
}
.position-panel .panel-body .text-right .btn
{
    float: right;
}
.holder-control .btn-tips:first-child
{
    float: left;
}
.holder-control .btn-tips:last-child
{
    float: right;
}
.holder-control .btn-tips
{
    padding: 6px 7px;
}
.holder-control .btn-tips > i
{
    margin-right: 0;
}
.hipervideos-list
{
    margin: 20px 0 0;
}
.archive-list
{
    margin: 20px 0 0;
}
.hipervideos-list .hipervideo-item:last-child
{
    margin-bottom: 0;
}
.archive-list .archive-item:last-child
{
    margin-bottom: 0;
}
.archive-item
{
    display: block;

    margin-bottom: 20px;
    padding: 12px 6px;

    color: #2b425b;
    border: 1px solid #1690f3;
    border-radius: 3px;
}
.hipervideo-item
{
    display: block;

    margin-bottom: 20px;
    padding: 12px 6px;

    color: #2b425b;
    border: 1px solid #e0221f;
    border-radius: 3px;
}
.text-hipervideo > i
{
    font-size: 16px;

    vertical-align: sub;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ab46bc+0,ff008d+100 */

    background: rgb(171,70,188); /* Old browsers */
    background:    -moz-linear-gradient(top,  rgba(171,70,188,1) 0%, rgba(255,0,141,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(171,70,188,1) 0%,rgba(255,0,141,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background:         linear-gradient(to bottom,  rgba(171,70,188,1) 0%,rgba(255,0,141,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ab46bc', endColorstr='#ff008d',GradientType=0 ); /* IE6-9 */
    -webkit-text-fill-color: transparent;
}
.text-archive > i
{
    font-size: 16px;

    vertical-align: sub;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#30c3fb+0,8400ff+100 */

    background: rgb(48,195,251); /* Old browsers */
    background:    -moz-linear-gradient(top,  rgba(48,195,251,1) 0%, rgba(132,0,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(48,195,251,1) 0%,rgba(132,0,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background:         linear-gradient(to bottom,  rgba(48,195,251,1) 0%,rgba(132,0,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    -webkit-background-clip: text;

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30c3fb', endColorstr='#8400ff',GradientType=0 ); /* IE6-9 */
    -webkit-text-fill-color: transparent;
}
.panel-accordion .panel-heading .panel-title
{
    position: relative;
    z-index: 9;

    border-radius: 5px 5px 0 0;
}
.cap-item
{
    display: block;

    margin-bottom: 10px;
}
.cap-item:last-child
{
    margin-bottom: 0;
}
.cap-item .time
{
    float: right;
}
.cap-item.block
{
    color: #d1d9de;
}
.try-evaluate
{
    display: block;

    margin-bottom: 10px;
}
.try-opacity
{
    opacity: .3;
}
.accordion > .panel
{
    border: 0;
}
.action-holder
{
    margin-top: 20px;
}
.panel-group.accordion .panel:last-child
{
    overflow: visible;
}
.modal .modal-content .modal-header .modal-info .panel-title > i
{
    font-size: 18px;

    vertical-align: sub;
}
.text-mar-btm
{
    margin-bottom: 25px;
}

.descr-video
{
    color: #fff;
}
.video-holder
{
    position: relative;
}
.descr-video .title-video
{
    font-size: 22px;

    color: #fff;
}
.video-holder .hipervideo .hiperlink
{
    display: inline-block;

    width: 60px;
    height: 45px;

    text-align: center;

    border-radius: 5px;
    background: transparent;
}
.video-holder .hipervideo
{
    position: absolute;
    z-index: 10;
    top: 20px;
    right: 5px;

    cursor: pointer;
}
.position-panel.panel-chapter
{
    display: block;
}
.panel-chapter .panel-body
{
    padding: 0 20px 20px;
}
.panel-chapter .panel-body .panel-heading
{
    margin: 0 -20px;
    margin-bottom: 25px;
    padding: 5px 20px;

    border-bottom: 1px solid #e7ecf3;
}
.caps-list .chapter-name
{
    display: inline-block;
    overflow: hidden;

    width: 190px;

    vertical-align: sub;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ead-page .nano-content.no-scroll,
.ead-page .nano-menu-simple .nano-content
{
    overflow: hidden;
}
.panel-accordion .position-panel .hipervideos-list .text-hipervideo
{
    display: inline-block;
    overflow: hidden;

    width: 100%;

    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.action-holder .panel-group
{
    margin-bottom: 65px;
}

//Modal Hipervideo 
.ead-page .video-holder .modal
{
    top: 35%;
}
.video-holder .modal .modal-dialog
{
    width: 100vw;
    margin: 0 auto;
}
.video-holder .modal .modal-dialog .modal-header
{
    display: flex;

    justify-content: space-between;
}
.video-holder .modal .modal-dialog .modal-title
{
    overflow: hidden;

    width: 90%;

    white-space: nowrap;
    text-overflow: ellipsis;
}
.video-holder .modal .modal-dialog .text-close-modal
{
    margin: 2px 0 0 9%;
}
.video-holder .modal .modal-dialog .modal-body iframe
{
    width: 100%;
    height: auto;
}
.panel-chapter .panel-body .panel-heading
{
    margin-bottom: 15px;
}
.panel-chapter .panel-body .panel-heading .panel-title
{
    line-height: 25px;
}
// .tips-list.owl-carousel {
//   display: block;
// }
#slider-tips .item
{
    display: block;

    width: 100%;
    height: auto;

    text-align: center;

    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;
}
.ead-page .slider-banner
{
    overflow: hidden;
    background: #000;
    .content-area
    {
        padding: 0;
    }
}
.slick-list {
    overflow: visible!important;
}
.ead-page
{
    overflow-x: hidden;

    background-color: #F5F5F5;
}
.ead-page .panel-user .text
{
    color: #fff;
}
.ead-page .avatar-image img
{
    width: 100%;
}
.ead-page .logo-img
{
    display: inline-block;

    width: 115px;
    height: 52px;

    text-align: center;
}
.ead-page .logo-img .logo
{
    display: inline-block;
    width: 125px;
    height: 55px;
    text-indent: -9999px;

    background: url('/images/logo-normal.png') no-repeat;
    background-size: contain;
}
.ead-page .header-elements .logo-holder 
{
    position: absolute;
    left: 15px;
    margin-top: 5px;
}
.ead-page .nav li .icon-padding
{
    padding: 0;
}
.ead-page body
{
    background: #fff;
}
.ead-page #mainnav
{
    height: 100%;

    background: transparent;
}
.ead-page .searchbox
{
    margin-right: 0;
    position: relative;
    z-index: 1;
}
.ead-page #mainnav-container
{
    position: absolute;
    z-index: 4;
    top: -59px;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 420px;
    min-height: 0;
    padding-top: 40px;
}
.ead-page .nano-menu-simple
{
    height: 260px !important;
}
.ead-page .nano-menu-simple .panel-user
{
    height: auto;
}
.ead-page .nano-menu-simple .panel-user .pad-all
{
    padding: 5px 15px;
}
.ead-page .nano-menu-simple .panel-user .panel-footer
{
    display: none;
}
.ead-page .nano-menu-simple .panel-user .media
{
    display: flex;

    height: 41px;
    margin-top: 0;
}
.ead-page .nano-menu-simple .panel-user .media .media-body
{
    display: inline-block;

    width: auto;
}
.ead-page .nano-menu-simple .panel-user .media .media-body a
{
    display: block;
    overflow: hidden;

    width: auto;
    margin-top: 2px;
    margin-left: 10px;

    white-space: nowrap;
    text-overflow: ellipsis;
}
.ead-page .notification-item
{
    margin-bottom: 10px;

    border-bottom: 1px solid #e6e6e6;
}
.ead-page .img-radius
{
    border: 1px solid #fff;
    border-radius: 50%;
}
.ead-page .panel-user
{
    margin: 55px 0 0;

    text-align: center;

    color: #fff;
    border: 0;
    background: #e0221f;
    background: url('/images/texture-bg-dark.png') right top;
}
.ead-page .panel-user .panel-header
{
    height: 50px;

    background: #e0221f;
    background: url('/images/texture-bg-darker.png') right top;
}
.ead-page .panel-user .panel-footer
{
    text-align: left;

    background: #e0221f;
    background: url('/images/texture-bg-darker.png') right top;
}
.ead-page .panel-user .panel-footer a
{
    color: rgba(255, 100, 100, .6);
}
.ead-page .padding-top
{
    padding-top: 7px;
}
.ead-page .nav li a
{
    position: relative;

    display: block;

    padding: 10px 15px;
}
.ead-page .mobile-menu
{
    display: none;

    text-align: left;
}
.ead-page .mobile-menu .item-nav
{
    padding: 10px 0;
}
.ead-page .mobile-menu .item-nav a
{
    text-transform: uppercase;

    color: #fff;
}
.ead-page .menu-display
{
    display: none;
}
.ead-page .footer-page img
{
    width: 100%;
}
.ead-page .menu-display .item-nav a
{
    text-transform: uppercase;
}
.ead-page .navbar-top-links .btn-mobile
{
    top: 110%;
}
.ead-page .navbar-main li a
{
    display: table-cell;

    height: 40px;
    padding: 0 12px;

    transition: all .4s;
    vertical-align: middle;

    color: #758697;
}
.ead-page .header-holder .nav-holder .nav-menu ul .menu-item
{
    margin-bottom: 15px;
    display: block;
}
.ead-page .header-holder .nav-holder .nav-menu ul .menu-item .menu-link
{
    color: #666;
    font-size: 14px;
}
.ead-page .header-holder .nav-holder .nav-menu ul .menu-item:last-child
{
    margin-bottom: 0;
}
.ead-page .search-area .search-form
{
    margin-right: 0;
}
.ead-page .search-area .search-icon
{
    position: absolute;
    top: 8px;
    right: 10px;
    color: #7F7F7F;
}
.ead-page .search-area .searchbox > .input-group.custom-search-form input
{
    color: rgba(91, 66, 46, 0.5);
    border-bottom-color: rgba(91, 66, 46, 0.5);
    font-size: 14px;
    line-height: 19px;
    padding: 6px 8px;
}
.top-bar .hamburger-button
{
    position: absolute;
    top: 5px;
    left: 15px;
}
.ead-page .header-elements .nav-holder .menu-area {
    display: none;
}
.ead-page .header-elements .container-fluid .nav-holder .container-fluid
{
    padding: 0;
}
.top-bar .hamburger-button .btn-mobile-ead
{
    padding: 2px 7px;
    font-size: 16px;
    border: 1px solid #e0221f;
    border-radius: 5px;
    color: #e0221f;
    background-color: white;
}
.ead-page .search-area .form-control::placeholder
{
    color: #999;
}
.ead-page .header-holder .name-holder 
{
    text-align: center;
    margin: 20px 0 10px;
}
.ead-page .header-holder .name-holder .name-user .user-avatar
{
    display: inline-block;
}
.ead-page .notification-list
{
    right: -59px;
}
.ead-page .searchbox .custom-search-form
{
    width: 100%;
}
.ead-page ::-webkit-input-placeholder
{
    color: #fff;
}
.ead-page .cards-area
{
    margin-top: 0;
}
.ead-page :-moz-placeholder
{
    color: #fff;
}
.ead-page .area-banner .banner
{
    height: auto;
    margin-bottom: 30px;

    background-color: #d8d8d8;
}
.ead-page .information-area
{
    margin-top: 0px;
}
.ead-page .content-holder
{
    //margin-top: 10px;
    background-color: #F5F5F5;
}
.ead-page .information-area .title
{
    font-size: 12px;
    font-weight: 600;

    margin-bottom: 5px;
    margin-top: 15px;
    text-transform: uppercase;

    color: #000;
}
.ead-page .information-area .tag-item
{
    display: inline-block;

    margin-right: 3px;
    margin-bottom: 10px;
}
.ead-page .information-area .label-tag
{
    font-size: 10px;

    display: inline-block;

    width: auto;
    padding: 2px 5px;

    text-align: center;

    color: #fff;
    border-radius: 5px;
    background: #666;
}
.ead-page .information-area .tags-area
{
    padding-bottom: 10px;

    border-bottom: 2px dashed #ccc;
}
.ead-page .information-area .uc-course .uc-title
{
    font-size: 12px;
    font-weight: 600;

    display: block;

    margin-bottom: 12px;
    padding: 6px 10px;

    color: #ff6b4d;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}
.ead-page .courses-done
{
    margin-bottom: 0px;
}
.ead-page .courses-done .text
{
    font-size: 10px;
    color: #fd9727;
}
.ead-page .courses-approved
{
    margin-bottom: 0px;
}
.ead-page .courses-approved .text
{
    font-size: 10px;
    color: #30ad63;
}
.ead-page .courses-disapproved .text
{
    font-size: 10px;

    color: #e0221f;
}

.ead-page .information-area .title {
    color: #282828;
}

.ead-page .information-area .uc-course .uc-title
{
    margin-bottom: 24px;
}
.ead-page .information-area .last-course .uc-course .btn
{
    padding: 4px 25px;

    text-align: center;

    border-radius: 5px;
    background: #e0221f;
}
.ead-page .information-area .uc-course .btn i
{
    display: inline-block;

    margin-left: 10px;

    vertical-align: middle;
}
.ead-page .content-area
{
    padding: 40px 0 0;
}
.ead-page .mainnav-area
{
    display: block;

    height: 290px;
}
.ead-page .area-header
{
    margin-bottom: 30px;
}
[class^="col-"]:not(.pad-no)
{
    padding-left: 15px;
    padding-right: 15px;
}
.ead-page .area-header .title
{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    text-transform: uppercase;
    background: #e0221f;
    color: #fff;
    padding: 5px 10px;
}
.ead-page .card
{
    width: 100%;
    margin-bottom: 30px;

    border: 1px solid #EAE2D8;
    border-radius: 2px;
    background-color: #fff;
}
.ead-page .card.card-preview
{
    opacity: 0.6;
}
.ead-page .card.card-preview .card-title
{
    position: relative;
}
.ead-page .card.card-preview .card-title .uc-title
{
    color: #666;
}
.ead-page .card.card-preview .card-title .date-preview
{
    color: #BBB;
    font-weight: 600;
    position: absolute;
    top: 36px;
    padding: 0 20px;
    font-size: 11px;
}
.ead-page .card.card-preview .card-info .footer-info .link-uc
{
    font-weight: 600;
}
.ead-page .card img
{
    width: 100%;
}
@media screen and (min-width: 1024px) {
    .ead-page .card img
    {
        max-height: 140px;
    }
}
@media screen and (min-width: 1280px) {
    .ead-page .card img
    {
        max-height: 150px;
    }
}
.ead-page .navbar-top-links.pull-right > li > a:hover
{
    border-bottom: 1px solid #e6e6e6;
    background: #fff;
}
.ead-page .card .card-title .uc-title
{
    font-size: 14px;
    font-weight: 600;

    height: 30px;
    margin-bottom: 35px;
    padding: 0 20px;

    color: #5B422E;
}
.ead-page .card .card-description {
  display: none;
  padding: 0 20px;
  margin-bottom: 35px;
  @media screen and (min-width: 768px) {
    display: block;
  }
}
.ead-page .card .card-info .progress
{
    height: 7px;
}
.ead-page .card .card-info .footer-info
{
    padding: 15px 20px;
    border-top: 1px solid #EAE2D8;
}
.ead-page .card .link-uc
{
    font-size: 11px;

    float: left;

    color: #fd9727;
}
.ead-page .card .link-uc.uc-done
{
    color: #40800f;
}
.ead-page .card .link-uc.uc-avalaible
{
    color: #0054f5;
}
.ead-page .card.uc-blocked
{
    opacity: .6;
}
.ead-page .card .link-uc.uc-blocked
{
    color: #8b8b8b;
}
.ead-page .card .link-uc.uc-unlocked
{
    color: #076A42;
}
.ead-page .card .link-uc i
{
    margin-right: 3px;

    vertical-align: baseline;
}
.ead-page .card .icon-right
{
    float: right;
}
.ead-page .card .icon-right i
{
    font-size: 12px;
    color: #076A42;
    position: relative;
    top: 2px;
}
.ead-page .banner img
{
    width: 100%;
}
.ead-page .curse-page
{
    clear: both;
}
.ead-page .mainnav-area > header
{
    position: relative;
    z-index: 9;

    margin: 0 -15px;
}
.ead-page .navbar-main li .open-mobile-menu
{
    color: #fff;
}
.ead-page .data-page .area-footer
{
    text-align: right;
}
.ead-page .data-page .area-footer .button-edit
{
    font-size: 12px;

    color: #fff;
    border-radius: 5px;
    background: #e0221f;
}
.ead-page .navbar-top-links:last-child > li
{
    border-left: 1px solid #e6e6e6;
}
.ead-page .img-md
{
    width: 50px;
    height: 50px;
}
.ead-page .nano-menu-simple .img-md
{
    width: 34px;
    height: 34px;
}
.ead-page .text-forgot
{
    display: inline-block;

    margin-bottom: 15px;
}
.ead-page .admin-actions 
{
    background: #291E15;
    text-align: right;
}
.ead-page .admin-actions .links-holder
{
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    
    @media screen and (min-width: 768px)
    {
        height: 30px;
        flex-wrap: nowrap;
    }
}

.ead-page .admin-actions .links-holder > div > a
{
    display: inline-flex;
    align-items: center;
    padding: 0px 15px;
    border: none;

    &:before
    {
        display: none;
    }
}

.ead-page .admin-actions .links-holder > div > a i
{
    font-size: 18px;

    &.ti-lock,
    &.ti-power-off,
    &.ti-stats-up
    {
        font-size: 14px;
    }

    @media screen and (min-width: 768px)
    {
        margin-right: 5px;
    }
}

.ead-page .select-holder .select-role
{
    display: inline-block;
    width: 150px;
    margin: 6px 15px;
    padding: 3px 5px;
    color: #e0221f;
    border: 1px solid #bfbdbd;
    border-radius: 3px;
    background: #fff;
    outline: none;
    appearance: none;
    -moz-appearance: none;
}
.ead-page .select-holder .ti-angle-down
{
    font-size: 11px;
    position: absolute;
    color: #e0221f;
    top: 15px;
    right: 22px;
    display: inline-block;
    pointer-events: none;
}
.ead-page .admin-actions .admin-btn {
    color: #f1f1f1;
    border-radius: 10px;
    padding: 5px 15px;
}

.ead-page .admin-actions {
    a {
        position: relative;
        color: #f1f1f1;
        line-height: 30px;
        transition: all .1s;

        &:hover {
            color: #fff;
        }
    }
}
.ead-page .modal-approve-ucs
{
    .modal-dialog{
        width: 90%;
    }
}

.ead-page .modal-approve-ucs.modal-seu-bolso{
    .modal-dialog{
        width: 500px;
        max-width: 80%;
        box-sizing: border-box;
    }
    .modal-body{
        .btn{
            float: right;
            transform: rotate(45deg);
            font-size: 25px;
            padding: 0;
            line-height: 10px;
            border: none;
            margin-bottom: 10px;
        }
    }
}

.ead-page .modal-exercise
{
    bottom: 0;

    margin: 30px auto;
}
.ead-page .modal-exercise .modal-dialog
{
    display: block;

    width: 100%;
    margin: 0 auto;
}
.ead-page .modal.modal-exercise:before
{
    height: 0;
}
.text-practice
{
    line-height: 21px;

    margin: 20px 0;

    text-align: justify;
}
.info-course .text-right .course-details
{
    float: left;

    margin-top: 7px;
}
.info-course .text-right .course-details-required
{
    display: flex;
    float: none;

    margin-top: 0;
    margin-bottom: 15px;

    justify-content: space-around;
}
.info-course .text-right .course-details .item-details
{
    margin-right: 8px;
}
.info-course .text-right .course-details .item-details:last-child
{
    margin-right: 0;
}

.answers-item.selected
{
    background: #758697;
    span
    {
        color: #fff;
    }
}

@keyframes text-danger-alternate
{
    to
    {
        color: #e0221f;
    }
}

.text-danger-alternate
{
    animation: text-danger-alternate 1s infinite ease-in-out both;

    color: white!important;
}


/* COMPONENTE DE VÍDEOS */
.pointer
{
    cursor: pointer;
}

.your-answer,
.correct-answer
{
    display: inline-block;
}

.checked
{
    color: #fff;
    border: 1px solid #2b425b;
    background: #758697;
}

.answers .correct
{
    font-size: 12pt;

    color: #86c042;
}

.answers-multiple .answers-item {
    cursor: pointer;
}

.answers-multiple .checked {
    color: #fff;
}

/* UC */
.test-results .approved
{
    color: green;
}

.test-results .no-padding
{
    padding: 0 !important;
}

.test-results .txt-center
{
    text-align: center !important;
}

.test-results .row
{
    margin: 0;
    padding: 4px 0;

    border-top: 1px solid #ddd;
}

.test-results .row:last-child
{
    padding: 0;
}

.test-results .modal-header
{
    padding: 0 4px;
}

.test-results .modal-header > div
{
    min-height: 42px;
    margin: 5px 0;
    padding: 10px 5px;
}

.test-results .modal-header > div:first-child
{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.test-results .modal-header > div:last-child
{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.test-results .modal-header .btn-close
{
    float: right;
}

.test-results  *
{
    color: #4f5861;
}

.test-results .mobile
{
    display: none;
}

.test-results .mobile .row:last-child
{
    padding-bottom: 4px;
}

.test-results .desktop
{
    display: block;

    padding: 10px;
}

.test-results .pd-10
{
    padding: 10px !important;
}

.test-results thead
{
    font-weight: bold;
}

.test-results .bold
{
    font-weight: bold;
}

.test-results .ti-thumb-up,
.test-results .ti-thumb-down,
{
    font-size: 15pt;
}

.test-results .approved { color: green; }
.test-results .disapproved { color: #e0221f; }

.scorm-course {
    border: none;
}
.nav-holder .action-nav-holder {
    display: inline-block;
    position: absolute;
    top: 2px;
    right: 0;
}


/* Busca de UC */
.search-results
{
    position: relative;
    left: 0px;

    display: none;

    width: 100%;
    padding-top: 10px;

    border: 1px #f1f1f1 solid;
    border-top: 0;
    background-color: white;
    z-index: 10;
    .search-content
    {
        overflow-y: auto;

        height: 100%;
        max-height: 270px;
    }
    .search-alert
    {
        display: block;

        margin-bottom: 10px;
        padding: 5px 10px;

        transition: all .1s;
    }
    .search-item
    {
        display: block;

        margin-bottom: 5px;
        padding: 5px 10px;
        padding-bottom: 5px;

        transition: all .1s;

        border-bottom: 1px solid #f1f1f1;
        &:last-child
        {
            margin-bottom: 0;
        }
        img
        {
            float: left;

            width: 96px;
            height: 54px;
            margin-right: 10px;
        }
        .content
        {
            span
            {
                display: block;

                padding: 0 0 10px 0;
            }
        }
        &:hover
        {
            color: #1c1c1c;
            background-color: #f1f1f1;
        }
    }
}

#polishop_comvc_banner {
    margin: 0 auto;
}

.banner-comvc {

  .list-group {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .list-group-item {
      width: 33.33%;
  }
  .banner-image {
      margin-bottom: 10px;
  }
}

.your-answer
{
    padding: 20px;

    border-right: 1px solid #eee;
}

.y-answer-title
{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 20px;
}

.feedback
{
    padding: 0 0 20px;
    padding-left: 20px!important;
}

.feedback-response
{
    font-size: 28px;

    margin-bottom: 10px;

    text-align: center;
}

.feedback-question {
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.question-connect {
    padding: 10px 0px 10px 30px;
}

// Switch True and False

li {
  list-style: none;
}

.question-truefalse .option {
  float: left;
  display: inline-block;
  margin: 10px;
  width: calc(100% - 120px);
}

.question-truefalse .toggle-switch{
  float: left;
}

.toggle-switch {
    display: inline-block;
    //border: 1px #c7c7c7 solid;
}
.checkbox.toggle.switch {
    width: 100px;
    cursor: pointer;
    height: 35px;
}
.switch .slide-button,
.toggle p span {
    display: none;
}
.toggle {
    position: relative;
    padding: 0;
}
.toggle label {
    position: relative;
    z-index: 3;

    display: block;
    width: 100%;
}
.toggle input {
    position: absolute;
    opacity: 0;
    z-index: 5;
}
.toggle p {
    position: absolute;
    left: -100px;
    width: 100%;
    margin: 0;
    padding-right: 100px;
    text-align: left;
    font-family: OpenSans-SemiBold, "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: .8em;
}
.toggle p span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 100px;
    text-align: center;
}
.toggle p span:last-child {
    left: 50%;
}
.toggle .slide-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
    display: block;
    width: 50%;
    height: 100%;
    padding: 0;
}
.toggle {
    display: block;
    height: 30px;
}
.toggle * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}
.toggle .slide-button {
    display: block;

    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.toggle label,
.toggle p {
    line-height: 30px;
    vertical-align: middle;
}
.toggle input:checked ~ .slide-button {
    right: 50%;
    background-color: #8bc34a;
}
.toggle input:focus ~ .slide-button{
}
/* Bugfix for older Webkit, including mobile Webkit.
 * http://css-tricks.com/webkit-sibling-bug/
 */
.switch, .toggle {
    -webkit-animation: bugfix infinite 1s;
}
@-webkit-keyframes bugfix { from { position: relative; } to { position: relative; } }

.switch {
    background-color: #D6D6D6;
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-shadow: 1px 1px 1px #191b1e;
    border-radius: 1px;
    padding: 2px 2px;
}

.switch input:checked + label {
    color: #333;
}

.switch .slide-button {
    border: 2px solid #D6D6D6;
    background-color: #e0221f;
    border-radius: 1px;
}

.switch p {
    color: #333;
    text-shadow: none;
}

.switch span {
    color: #fff;
}

.question-balloon {
  .answers-balloons {
    display: block;
    width: 30%;
    float: left;
    min-height: 200px;
  }
  .answer-content {
    z-index: 99;
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid;
    border-radius: 3px;
    cursor: pointer;
    background-color: white;
    transition: border-color .3s;
  }
  .character-balloon {
    width: 70%;
    float: left;
    padding-left: 30px;
    height: 100%;

    .balloons {
      border: 1px solid #e0221f;
      background-color: rgba(239, 83, 80, 0.3);
      transition: background .3s;
      border-radius: 30px;
      width: 50%;
      height: 100%;
      min-height: 300px;
      padding: 20px;
      float: left;

      .answer-content {
        border-color: #e0221f;
      }
    }
    .character {
      position: relative;
      float: left;
      height: 300px;
      width: 200px;
      margin-left: 60px;
      background: url('../../images/character-female.png') 0/contain no-repeat;
    }
    .character-dialog {
      height: 40px;
      width: 60px;
      position: absolute;
      top: 0;
      left: -60px;

      .dialog {
        background-color: rgba(239, 83, 80, 0.3);
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 50px;
        border: 1px solid #e0221f;
        margin: 5px;
        position: absolute;
      }
      .d-1 {
        width: 20px;
        height: 18px;
      }
      .d-2 {
        left: 25px;
        top: 0px;
        width: 12px;
        height: 10px;
      }
      .d-3 {
        left: 40px;
        top: 10px;
      }
    }
  }
}


.practice-script {
    overflow-y: auto;
    height: 293px;
}
.questions-items .active {
    transition: all .2s linear;
    border-color: #6f90ff!important;
    box-shadow: #6f90ff 0px 0px 10px;
}

.form-group-icon {

    &:after, &:before {
        display: table;
        clear: both;
        content: '';
    }
    .input-icon {
        float: left;
        background-color: #e0221f;
        color: white;
        padding: 0 9px;
        height: 30px;
        border-radius: 0;

        i {
            vertical-align: -webkit-baseline-middle;
        }
    }
    .form-control {
        float: left;
        width: auto;
    }
}

/* #Events */

.filter-holder
{
    text-align: left;
    margin-top: 30px;
    border-bottom: 1px solid #000;

    .filter-events
    {
        width: 100%;
        display: inline-block;
    }

    @media screen and (min-width: 768px)
    {
        margin-top: 0px;

        .filter-events
        {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            > .form-group
            {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex: 1 1 215px;
                max-width: 215px;

                > label
                {
                    flex: 0 0 auto;
                    max-width: max-content;
                    margin-right: 10px;
                    margin-bottom: 0px;
                }

                > select
                {
                    flex: 0 0 200px;
                    max-width: 200px;
                }
            }
        }
    }

    @media screen and (min-width: 1024px)
    {
        margin-top: 0px;

        .filter-events
        {
            margin-bottom: 0px;

            .form-group
            {
                margin-bottom: 15px;
            }
        }
    }
}

.event-list {
    padding: 0;

    .event-item {
        margin: 20px 0;
        color: #000;
        border-bottom: 1px solid #dedede;
        position: relative;
        padding: 0 15px;

        &:before, &:after {
            clear: both;
            display: table;
            content: '';
        }

        .event-header {
            margin-bottom: 30px;

            .event-title {
                line-height: 40px;
                margin: 0;
                color: #000;
            }

            .event-subtitle {
                font-size: 12px;
                
                span.date:after,
                span.organizer:after,
                span.hour:after {
                    content: '-';
                    padding: 5px;
                }
            }
        }

        .event-detail {
            margin: 30px 0 10px;
        }

        @media screen and (min-width: 1024px)
        {
            .event-header
            {
                float: left;
            }

            .event-detail
            {
                float: right;
                margin-top: 18px;
            }
        }
    }

    > h2
    {
        margin-bottom: 30px;
    }
}

/* #Event Show */
.event-content {
    margin-bottom: 30px;

    .event-image {
        width: 100%;
        max-width: 300px;
        float: left;
        margin-right: 30px;
        
        img {
            width: 100%;
        }
    }

    .event-description {
        float: left;

        a {
            margin-top: 10px;
        }
    }
}

.event-gallery {

    .event-image-list {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .event-image {
            position: relative;
            height: 250px;
            padding-bottom: 10px;
            flex-basis: 23%;
            &:nth-child(4n)
            {
                margin-right: 0;
            }
            
            .image {
                position: relative;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                width: 100%;
                height: 100%;
                cursor: pointer;
                border: 1px solid #e9e9e9;

                &:hover .open-image {
                    opacity: 1;
                }

                .open-image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    background-color: rgba(0,0,0,.3);
                    transition: all .2s;

                    i {
                        color: rgba(255,255,255, .7);
                        font-size: 50px;
                        height: 50px;
                        width: 50px;
                        margin: auto;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}

.content-area
{
    .area-banner
    {
        margin-bottom: 30px;
    }
}

.last-access {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 12px;

    span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        background: #ccc;
        border: 1px solid #999;
    }
}

/* #COMVC */

.form-ead {
    .btn {
        border: none;
    }
}
.comvc {
    // margin-bottom: 30px;
    // .search-area {
    //     width: 50%;
    //     left: 100px;
    // }

    .menu {
        display: flex;
        justify-content: space-between;

        .menu-item {
            position: relative;
            width: 25%;
            text-align: center;
            cursor: pointer;

            &.active .menu-item-icon, &:hover .menu-item-icon {
                background-color: #ed1c24;
                color: white;

                i {
                    transform: scale(1.4);
                }
            }

            &:hover {
                .sub-menu {
                    display: block;
                }
            }

            .menu-item-icon {
                width: 70px;
                height: 70px;
                font-size: 24px;
                border: 2px solid #ed1c24;
                color: #ed1c24;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto 7px;
                transition: all .1s linear;

                i {
                    transition: all .2s linear;
                }
            }

            .menu-item-title {
                text-align: center;
                color: #ed1c24;
            }

            .sub-menu-cover {
                position: absolute;
                top: 0;
                width: 100%;
                height: 120%;

                &:hover {
                    .sub-menu {
                        display: block;
                    }
                }
            }

            .sub-menu {
                display: none;
                position: absolute;
                left: -40px;
                right: -40px;
                margin: auto;
                top: 110px;
                padding: 10px;
                border-radius: 10px;
                background-color: #e0221f;
                z-index: 10;
                width: 150px;

                li {
                    z-index: 10;
                    border-bottom: 1px solid rgba(255,255,255,.6);
                }

                li:last-child {
                    border: none;
                }

                &:before {
                    content: '';
                    display: block;
                    background-color: #e0221f;
                    position: absolute;
                    top: -10px;
                    left: 0;
                    border-style: solid;
                    right: 0;
                    width: 10px;
                    margin: auto;
                    border-width: 0 10px 10px 10px;
                    border-color: #fafafa #fafafa #e0221f #fafafa;
                }

                .sub-menu-item {
                    font-size: 12px;
                    display: block;
                    text-align: left;
                    color: white;
                    padding: 5px 0;

                    &:hover {
                        opacity: .8;
                    }
                }
            }
        }
    }

    .share {

        .share-item {
            position: relative;
            text-align: left;
            font-size: 14px;
            padding-left: 5%;
            margin-bottom: 5px;

            .share-title {
                color: #ed1c24;
                padding: 7px 15px;
                background-color: white;
                border-radius: 10px;
                border: 2px solid #ed1c24;
                display: inline-block;
                width: 150px;
                cursor: default;

                i {
                    font-size: 20px;
                }
            }

            .link {
                display: block;
            }

            .copy {
                padding: 3px 10px;
                border-radius: 5px;
                border: 1px solid #999;
                background-color: #999;
                color: white;
                position: relative;
                top: -5px;
                transition: all .2s;
                cursor: pointer;
                margin-left: 10px;

                &:before {
                    content: '';
                    display: block;
                    background-color: #999;
                    position: absolute;
                    top: 7px;
                    left: -9px;
                    border-style: solid;
                    width: 0;
                    height: 0;
                    margin: auto;
                    border-width: 5px 8.7px 5px 0;
                    border-color: #fafafa #999 #fafafa #fafafa;
                }

                &:hover {
                    background-color: white;
                    color: #999;
                }
            }

            .share-link {
                display: inline-block;
                color: #444;
                padding-left: 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}

/* FOOTER */
.footer {
    padding: 26px 0;
    background-color: #291E15;
    color: white;
    font-family: 'Exo';
    
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    a {
        font-family: 'Exo';
    }

    .left-content {
        text-align: left;
        
        div {
            font-family: 'Exo';
        }

        > div {
            margin: 20px 0;
            font-size: 19px;
        }
        .logo {
            margin: 30px 0;
        }
    }

    .comvc .left-content {
        .logo {
            margin: 40px 0;
        }
    }

    .comvc .right-content {
        > div {
            font-size: 26px;
            margin: 0;
        }
    }

    .right-content {
        text-align: right;
        
        div {
            font-family: 'Exo';
        }

        > div {
            font-size: 14px;
            margin: 10px 0;
        }

        .contact a {
            font-size: 19px;
        }

        .address {
            margin-bottom: 30px;
        }

        .text-red {
            color: #e0221f;
        }

        div.text-big {
            font-size: 89px;
        }

        div.text-normal {
            font-size: 37px;
        }
    }

    .footer-content {
        width: 50%;
    }

    .contact {
        a {
            display: block;
            font-size: 26px;
            line-height: 24px;
            color: #e0221f;
        }
    }
}
/* END FOOTER */

/* Media Queries */
@media screen and (max-width: 767px){

    .question {
        .item-connect.answer-connect {
            margin-left: 40px;
        }
        .question-simple .answer {
            left: 0px;
        }
    }

    .question-balloon {
        div.character-balloon {
            width: 100%;
            float: none;
            padding-left: 0px;
        }
        ul.answers-balloons {
            width: 100%;
            float: none;
            margin-bottom: 20px;
            min-height: 10px;
        }
        .character-balloon ul.balloons {
            width: 100%;
            height: auto;
        }
        .character-balloon div.character {
            width: 130px;
            float: right;
        }
        .character-balloon .character-dialog .d-1 {
            top: -12px;
        }
    }

    .event-gallery .event-image-list li.event-image {
        flex-basis: 49%;
        padding: 0;
        padding-bottom: 10px;
    }
    .event-content {
        text-align: center;

        div.event-image {
            float: none;
            margin: 0 auto 10px;
        }

        div.event-description {
            margin-left: 0;
        }
    }
}

/* FOOTER MOBILE */
@media screen and (max-width: 768px) {
    .footer {
        .social {
            justify-content: center;
            flex-wrap: wrap;

            .social-item {
                margin: 10px;
            }
        }

        .container, .container.comvc {
            justify-content: center;
            flex-wrap: wrap;
        }
        .footer-content {
            width: 100%!important;
            text-align: center!important;
        }
    }
}

@media screen and (max-width: 425px) {
    .footer {

        .left-content {
            > div {
                margin: 10px 0;
                font-size: 14px;
            }

            a {
                font-size: 16px;
            }
        }
        .right-content {
            div.text-big {
                font-size: 60px;
            }

            div.text-normal {
                font-size: 24px;
            }
        }
    }
}
/* END FOOTER MOBILE */

@media screen and (max-width: 480px) {
    .event-gallery .event-image-list li.event-image {
        flex: 1 100%;
        padding: 0 0 15px 0;
    }

    .expiry-content {
        margin-bottom: 0;
    }

    .comvc {
        ul.menu {
            margin-top: 30px;
            flex-wrap: wrap;
            justify-content: flex-start;
            li.menu-item {
                width: 33%;
                margin-bottom: 30px;
            }
        }

        .share {
            .share-item {
                margin-bottom: 30px;
                padding: 0;
            }
            .share-link {
                display: inline-block;
                direction: rtl;
                padding: 0;
                width: 100%;
            }
        }
    }

    .row {
        margin: 0;
    }

    .nav-holder {
        margin-top: 30px;
    }

    .ead-page .header-holder .name-holder {
        position: absolute;
        top: 80px;
        right: 15px;
        width: 50%;
        margin: 0;

        .name-user {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .user-avatar img {
            width: 25px;
            height: 25px;
        }

        .text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 5px;
        }
    }
    
    .accordion-item {
        display: flex;
        align-items: center;
        height: 30px;

        .accordion-image {
            width: 30px;

            img {
                width: 100%;
            }

            &.normal {
                display: none;
            }
        }
    }

    .banners-slider .item {
        margin: 0!important;
    }

    .modal-dialog {
        margin: 0;
        width: 100%;
    }
}

.ead-page .banners-holder
{
    margin: 12px 0 30px;
}
.ead-page .banners-holder .area-banner
{
    margin-bottom: 0px;
}

@media screen and (min-width: 768px)
{
    .banners-holder .content-area .area-banner .banners-slider img
    {
        width: 100%;
    }
    .comvc {
        .menu {
            margin-bottom: 30px;
        }
    }
    .ead-page .content-holder
    {
        padding-top: 20px;
    }
    .header-holder .header-elements .top-bar
    {
        margin: 0;
        margin-right: 0px;
        height: 88px;
        border-bottom: 0;
    }
    .ead-page .header-holder .header-elements .container
    {
        position: relative;
    }
    .ead-page .header-elements .logo-holder 
    {
        position: relative;
        left: 0;
        top: 11px;
        z-index: 10;
    }
    .nav-holder .action-nav-holder
    {
        right: 25px;
    }
    .header-holder .menu-area 
    {   
        margin-left: 0;
        width: 100%;
        margin-bottom: -10px;
        margin-top: 10px;
        padding: 10px 0;
    }
    .ead-page .header-holder .nav-holder .nav-menu ul
    {
        margin-bottom: 0;
    }
    .ead-page .header-holder .nav-holder .nav-menu ul .menu-item
    {
        margin: 30px 20px;
        display: inline-block;
    }
    .ead-page .header-holder .nav-holder .nav-menu ul .menu-item .menu-link
    {
        display: inline-block;
        background: #FFF;
        padding: 8px 11px;
        border-radius: 10px;
    }
    .ead-page .header-holder .nav-holder .nav-menu ul .menu-item:first-child
    {
        margin-left: 0;
    }
    .ead-page .header-holder .name-holder
    {
        position: absolute;
        top: 0px;
        bottom: 0;
        right: 15px;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .ead-page .top-bar .hamburger-button
    {
        margin-right: 0;
        margin-top: 14px;
        left: 185px;
    }
    .info-course .text-right .course-details .item-details
    {
        margin-right: 20px;
    }
    .info-course .text-right .course-details-required
    {
        float: left;

        margin-top: 7px;
        margin-bottom: 0;
    }
    .panel-tips .panel-body .holder-control
    {
        position: absolute;
        bottom: 15px;

        width: 90%;
    }
    .modal-practice .modal-body
    {
        width: 50%;
        margin: 0 auto;
    }
    .modal-practice .modal-body > p
    {
        text-align: center;
    }
    .item-reaction-holder .panel .panel-body
    {
        width: 90%;
        padding: 0 20px;
    }
    .item-reaction-holder .panel .panel-body .pre-test
    {
        float: left;

        margin-top: 18px;
    }
    .item-reaction-holder .panel .panel-body .text-right
    {
        float: right;

        margin-top: 9px;
    }
    .ead-page .modal-exercise
    {
        margin: auto;
    }
    .ead-page .modal-exercise .modal-dialog
    {
        width: 90%;
		height: 90%;
    }
    .ead-page .modal-dialog .modal-content .modal-body .owl-wrapper .owl-item
    {
        width: 538px;
    }
    .ead-page .single-page
    {
        margin-top: 30px;
    }
    .ead-page .logo-xs
    {
        display: none;
    }
    .ead-page .logo-img .logo
    {
        width: 100%;
        height: 91px;
    }
    .ead-page .logo-sm
    {
        display: inline-block;

        width: 168px;
        height: 91px;
    }
    .ead-page .border-item-right
    {
        border-right: 1px solid #e6e6e6;
    }
    .ead-page .data-page.cards-area
    {
        margin-top: 170px;
    }
    .ead-page .notification-list
    {
        right: 0;
    }
    .ead-page .cards-area
    {
        margin-top: 0;
    }
    .ead-page .curse-page
    {
        margin-top: 170px;
    }
    .ead-page .mainnav-area > header
    {
        position: relative;
        z-index: 9;

        margin: 0;
    }
    .ead-page #mainnav-container
    {
        z-index: 11;
        top: -55px;
        left: auto;

        max-width: 170px;
        padding-top: 0;
    }
    .ead-page .panel-user .panel-header
    {
        height: 40px;
    }
    .ead-page .menu-display
    {
        display: -ms-flexbox;
        display: flex;

        margin-left: 0;
    }
    .ead-page .mobile-menu
    {
        display: none !important;
    }
    .ead-page .nav > .btn-mobile
    {
        display: none;
    }
    .ead-page .name-holder .name-user .user-avatar .img-md {
        height: 30px;
        width: 30px;
    }
    .ead-page .search-area
    {
        position: absolute;
        top: 28px;
        left: 210px;
        width: 300px;
    }
    .ead-page .searchbox
    {
        width: 100%;
        display: block;
    }
    .ead-page .item-nav
    {
        position: relative;
    }
    .ead-page .search-area .search-icon
    {
        top: 8px;

        color: #808080;
    }
    .ead-page .search-area .form-control
    {
        color: #b3b3b3;
    }
    .ead-page ::-webkit-input-placeholder
    {
        color: #b3b3b3;
    }
    .ead-page :-moz-placeholder
    {
        color: #b3b3b3;
    }
    .ead-page .mainnav-area
    {
        height: 40px;
    }
    .ead-page .content-area
    {
        clear: both;
        width: 100%;
        margin-bottom: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .ead-page .slider-banner .content-area
    {
        margin-bottom: 0;
    }
    .ead-page .cards-area
    {
        padding: 0;
    }
    .ead-page .navbar-content
    {
        margin-left: 173px;
    }
    .ead-page .footer-page img
    {
        display: block;

        width: 500px;
        margin: 0 auto;
    }
    .ead-page #navbar-container
    {
        box-shadow: none;
    }
    .ead-page #mainnav-container
    {
        height: 253px;
    }
    .ead-page .information-area
    {
        overflow: hidden;
        text-align: right;
        position: relative;
        top: -3px;
    }
    .ead-page.comvc-page .information-area
    {
        overflow: hidden;
        text-align: left;
        position: relative;
        top: 10px;
    }
    .header-holder.comvc .information-holder {
        margin-top: 50px;
        margin-bottom: 20px;
    }
    .ead-page .panel .pad-all
    {
        padding: 0 15px;
    }
    .ead-page .panel .pad-all .media
    {
        margin-top: 10px;
    }
    .ead-page .information-area .tags-area
    {
        width: 100%;
        margin-bottom: 25px;
        padding-right: 0;
        padding-bottom: 6px;

        border-bottom: 0;
    }
    .ead-page .information-area .last-course
    {
        float: right;

        width: 48%;
    }
    .ead-page .information-area .title
    {
        margin-top: 0;
        margin-bottom: 5px;
        font-weight: 900;
        color: #000;
    }
    .ead-page .courses-done
    {
        margin-bottom: 4px;
    }
    .ead-page .courses-approved
    {
        margin-bottom: 4px;
    }
    .ead-page .comvc .header-page .top-header
    {
        height: 200px;
    }
    .ead-page .information-area .label-tag
    {
        height: 18px;
    }
    .ead-page .information-area .label-tag span
    {
        line-height: 15px;

        display: block;
        overflow: hidden;

        width: 100%;

        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .ead-page .panel-user .panel-footer
    {
        height: 30px;
        padding: 5px 15px;
    }
    .ead-page .notification-list .notification-item
    {
        border-right: 0;
    }
    .ead-page .notification-list .bord-top .box-block
    {
        padding: 0;
    }
    .ead-page .header-elements
    {
        position: relative;
        padding-bottom: 0;
        margin: 0px;
    }
    .video-holder .modal .modal-dialog .modal-body iframe
    {
        height: 371px;
    }
    .modal-exercise .flexbox-area
    {
        display: block;

        width: 100%;
    }
    .modal-exercise .flexbox-area .btn
    {
        float: left;
    }
    .modal-exercise .flexbox-area .progress-exercise
    {
        position: absolute;
        right: 0;
        left: 0;

        width: 100px;
        margin: 0 auto;
    }
    .modal-exercise .flexbox-area .test-next-end #pretest-next,
    .modal-exercise .flexbox-area .test-next-end #end-pre-test
    {
        float: right;
    }

    .position-panel .holder-control
    {
        margin-top: 20px;
    }
    .panel-accordion .position-panel .tips-list.owl-carousel
    {
        margin: auto;
    }
    .ead-page .video-holder .modal
    {
        top: 15%;

        width: 600px;
        margin: 30px auto;
    }
    .video-holder .modal .modal-dialog
    {
        width: 100%;
    }
    .action-holder .panel-group .position-panel:first-child
    {
        right: 0;
    }
    .action-holder .panel-group .position-panel:nth-child(4)
    {
        right: 0;
    }
    .video-holder .hipervideo .hiperlink
    {
        width: 140px;
        height: 90px;
    }
    .video-holder .hipervideo
    {
        top: 30px;
        right: 10px;
    }
    div[data-uc-id="227"] .video-holder .hipervideo
    {
        right: auto;
        left: 10px;
    }
    .accordion > .panel
    {
        background-color: transparent;
    }
    .accordion > .panel:first-child
    {
        margin-top: 5px;
    }
    .icon-accordion > i
    {
        line-height: 63px;
    }
    .icon-accordion
    {
        width: 66px;
        height: 66px;

        border-radius: 50%;
        background: #000;
    }
    .accordion-active .icon-accordion
    {
        background: #fff;
    }
    .panel-accordion .panel-heading .text-center a
    {
        width: 100%;
        height: auto;
        padding-top: 20px;

        border-radius: 0 0 5px 5px;
        background: transparent;
    }
    .panel-relative
    {
        position: relative;

        width: 150px;
    }
    .gradient-circle
    {
        display: block;
        float: none;

        width: 72px;
        height: 72px;
        margin: 0 auto;
        padding: 3px;

        border-radius: 50%;
    }
    .panel-accordion .panel-heading .text-center .accordion-active
    {
        width: 100%;
    }
    .panel-accordion > .panel
    {
        border-radius: 5px 5px 0 0 !important;
    }
    .accordion-item > span
    {
        font-size: 16px;
        font-weight: 600;

        float: none;

        margin: 0 auto;
    }
    .panel-accordion .panel-heading .panel-title
    {
        border-radius: 0 0 5px 5px;
    }
    .circle-warning
    {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff9902+0,f2d000+100 */
        background: rgb(255,153,2); /* Old browsers */
        background:    -moz-linear-gradient(top,  rgba(255,153,2,1) 0%, rgba(242,208,0,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,153,2,1) 0%,rgba(242,208,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background:         linear-gradient(to bottom,  rgba(255,153,2,1) 0%,rgba(242,208,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9902', endColorstr='#f2d000',GradientType=0 ); /* IE6-9 */
    }
    .circle-success
    {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#8bc34a+0,d5ff00+100 */
        background: rgb(139,195,74); /* Old browsers */
        background:    -moz-linear-gradient(top,  rgba(139,195,74,1) 0%, rgba(213,255,0,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(139,195,74,1) 0%,rgba(213,255,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background:         linear-gradient(to bottom,  rgba(139,195,74,1) 0%,rgba(213,255,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8bc34a', endColorstr='#d5ff00',GradientType=0 ); /* IE6-9 */
    }
    .circle-purple
    {
        background: rgb(239,83,80); /* Old browsers */
        background:    -moz-linear-gradient(top,  rgba(239,83,80,1) 0%, rgba(255,0,119,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(239,83,80,1) 0%,rgba(255,0,119,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background:         linear-gradient(to bottom,  rgba(239,83,80,1) 0%,rgba(255,0,119,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef5350', endColorstr='#ff0077',GradientType=0 ); /* IE6-9 */
    }
    .circle-primary
    {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#30c3fb+0,8400ff+100 */
        background: rgb(48,195,251); /* Old browsers */
        background:    -moz-linear-gradient(top,  rgba(48,195,251,1) 0%, rgba(132,0,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(48,195,251,1) 0%,rgba(132,0,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background:         linear-gradient(to bottom,  rgba(48,195,251,1) 0%,rgba(132,0,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#30c3fb', endColorstr='#8400ff',GradientType=0 ); /* IE6-9 */
    }
    .circle-caps
    {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ab46bc+0,ff008d+100 */
        background: rgb(171,70,188); /* Old browsers */
        background:    -moz-linear-gradient(top,  rgba(171,70,188,1) 0%, rgba(255,0,141,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(171,70,188,1) 0%,rgba(255,0,141,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background:         linear-gradient(to bottom,  rgba(171,70,188,1) 0%,rgba(255,0,141,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ab46bc', endColorstr='#ff008d',GradientType=0 ); /* IE6-9 */
    }
    .panel-accordion .panel-heading .text-center .collapsed
    {
        background: transparent;
    }
    .panel-accordion .panel-heading .text-center .collapsed .icon-accordion
    {
        background: #000;
    }
    .panel-info-course > .panel-body
    {
        position: relative;

        padding-top: 15px;
    }
    .panel-info-course > .panel-body .descr-course
    {
        float: left;

        width: 60%;
        padding-top: 0;

        border-bottom: 0;
    }
    .panel-info-course > .panel-body .info-course
    {
        float: left;

        width: 40%;
        margin-top: -12px;
        padding-top: 15px;

        border-left: 1px solid #e7ecf3;
    }
    .try-evaluate
    {
        line-height: 30px;

        display: inline-block;

        margin-right: 20px;
        margin-bottom: 0;

        color: #758697;
    }
    .final-evaluation-holder .info-area > .text-right
    {
        display: flex;

        justify-content: space-between;
    }
    .panel-evaluate-success
    {
        display: flex;

        justify-content: space-between;
    }
    .panel-evaluate-success .panel-body
    {
        padding: 10px 20px;
    }
    .panel-title
    {
        line-height: 52px;
    }
    .panel-question{
        line-height: 40px;
        font-size: 16px;
    }
    .panel-evaluate-certificate .title-area
    {
        float: left;
        width: 25%;
    }
    .panel-evaluate-certificate .info-area
    {
        float: right;
        padding: 0;
        width: 75%;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 52px;
        p.pre-test
        {
            margin-bottom: 0;
            flex-basis: 56%;
        }
    }
    .panel-item-course .img-course
    {
        display: inline-block;
        float: left;

        width: 43%;
        height: 135px;
        margin-right: 30px;
        margin-bottom: 0;
    }
    .panel-item-course .info-course > p
    {
        margin-bottom: 35px;
    }
    .panel-item-course .info-course
    {
        float: left;

        width: 52.5%;
    }
    .modal .modal-header > .modal-info .panel-title
    {
        line-height: 20px;

        padding: 0 0 0 20px;
    }
    .modal .modal-header > .modal-info
    {
        text-align: right;
    }
    .modal-info > .button-area-info
    {
        display: flex;

        justify-content: space-between;
    }
    .button-area-info .text-close-modal
    {
        margin-top: 6px;
        margin-bottom: 0;
    }
    .time-count
    {
        display: block;

        margin-top: 10px;
    }

    .drag-holder
    {
        display: flex;

        margin-bottom: 120px;

        justify-content: space-around;
    }
    .drag-holder > .drag-item
    {
        margin-bottom: 0;
    }

    .video-holder
    {
        margin-top: 20px;
    }
    .video-holder > iframe
    {
        height: 315px;
    }
    .video-holder > iframe.scorm-course {
        height: 550px;
    }

    .action-holder > .panel-group
    {
        position: relative;

        display: flex;

        justify-content: flex-start;
    }
    .accordion > .position-panel.panel-chapter
    {
        top: -1px;
        right: 0;

        overflow: hidden;

        width: 260px;
        height: auto;
        max-height: 150px;

        background: #fff;
    }
    .position-panel
    {
        position: absolute;
        top: -230px;

        width: 338px;
        height: 230px;
    }
    .panel-accordion .position-panel
    {
        overflow: hidden;
    }
    .panel-accordion .position-panel .archive-list,
    .panel-accordion .position-panel .hipervideos-list
    {
        overflow-x: hidden;
        overflow-y: auto;

        height: auto;
        max-height: 120px;
        margin: 0 -20px;
        padding: 0 20px;
    }
    .panel-accordion .position-panel .tips-list,
    {
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: auto;

        max-height: 100px;
    }
    .holder-chapters .caps-list
    {
        margin-bottom: 10px;
    }
    .holder-chapters .caps-list:last-child
    {
        margin-bottom: 0;
    }
    // .position-panel-left {
    //   left: -90px;
    // }
    .position-panel-right
    {
        right: 0;
    }
    .attempts-area
    {
        display: block;

        margin-bottom: 15px;
    }
    .video-holder
    {
        position: relative;
    }
    .descr-video .title-video
    {
        font-size: 34px;
    }
    .descr-video .text-video
    {
        font-size: 16px;
    }
    .text-close-modal
    {
        margin-right: 20px;
    }
    .panel-sm-flex
    {
        display: flex;

        justify-content: space-between;
    }
    .item-practice-holder .panel .info-area .pre-test
    {
        float: left;

        margin-top: 17px;
        margin-bottom: 0;
    }
    .item-practice-holder .panel .info-area
    {
        width: 68%;
        padding: 0 20px;
    }
    .item-practice-holder .panel .info-area .text-right
    {
        float: right;

        margin-top: 10px;
    }
    .evaluate-unterminated
    {
        display: flex;

        justify-content: space-between;
    }
    .evaluate-unterminated .info-area
    {
        width: 70%;
        padding: 0 20px;
    }
    .evaluate-unterminated .info-area .pre-test
    {
        float: left;

        margin-top: 15px;
        margin-bottom: 0;
    }
    .evaluate-unterminated .info-area .text-right
    {
        float: right;

        margin-top: 9px;
    }
    .info-course-holder .img-course
    {
        height: 330px;
    }
    .holder-chapters
    {
        overflow-x: hidden;
        overflow-y: auto;

        max-height: 110px;
        margin: 0 -20px;
        padding: 0 20px;
    }
    .caps-list .chapter-name
    {
        width: 160px;
    }
    .modal-exercise .modal-info.modal-info-time.text-center
    {
        display: flex;

        justify-content: space-between;
    }
    .modal.modal-exercise .modal-content .modal-header .modal-info .panel-title
    {
        margin-top: 6px;
    }
    .ead-page .navbar-content
    {
        border-bottom: 1px solid #e6e6e6;
    }
    .descr-video .title-video
    {
        // overflow: hidden;

        // white-space: nowrap;
        // text-overflow: ellipsis;
    }
    .event-list .event-gallery .event-image-list
    {
        justify-content: flex-start;
    }
    .event-gallery .event-image-list li.event-image
    {
        margin-right: 19px;
    }
    .nav-holder .name-holder .name-user > a
    {
        max-width: 210px;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-align: right;
    }

    .accordion-item .text-hiper {
        color: white;
    }

    .accordion-image {
        width: 80px;
        height: 80px;
        padding: 0;
        margin: -4px auto;

        &.hover {
            display: none;
        }

        img {
            width: 100%;
        }
    }

    .accordion-active {
        .accordion-image {
            &.normal {
                display: none;
            }
            &.hover {
                display: block;
            }
        }
        .text-hiper {
            color: #e0221f;
        }
    }

    .search-area-desktop .custom-search-form .search-results
    {
        width: 541px;
        position: absolute;
        left: 15px;
    }
}

@media screen and (min-width: 1024px)
{
    .header-holder .menu-area .menu-item
    {
        border-left: 1px solid #B9B9B9;
    }
    .header-holder .menu-area .menu-item:last-child,
    .header-holder .menu-area .menu-item:first-child
    {
        border: none;
    }
    .ead-page .single-page
    {
        margin-top: 10px;
    }
    .container-md-fluid
    {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width: 1170px;
    }
    .header-holder .menu-area
    {
        margin-top: 0;
        padding: 21px 0;
        position: absolute;
        top: -1px;
        background: transparent;
        right: 7px;
        //width: calc(100% - 348px);
        width: calc(100% - 280px);
        margin-right: 0;
        display: inline-block !important;
    }
    .ead-page .nav-holder .name-holder .name-user > a
    {
        max-width: 250px;
    }
    .ead-page .logo-img
    {
        width: 170px;
        height: 70px;
    }
    .ead-page .header-elements .logo-holder
    {
        z-index: 10;
    }
    .ead-page .logo-img .logo
    {
        height: 90px;
    }
    .top-bar .hamburger-button .btn-mobile-ead
    {
        display: none;
    }
    .ead-page .header-holder .nav-holder .nav-menu ul .menu-item
    {
        margin: 0;
        //margin-right: 10px;
    }
    .ead-page .header-holder .nav-holder .nav-menu ul .menu-item:first-child
    {
        margin-left: 0;
    }
    .ead-page .header-holder .nav-holder .nav-menu ul .menu-item:last-child
    {
        margin-right: 0px;
        position: absolute;
        right: 0;
    }
    .ead-page .header-holder .nav-holder .nav-menu ul .menu-item .menu-link
    {
        //background: #E6E6E6;
    }
    .panel-md-flex
    {
        display: flex;
        align-items: center;

        justify-content: space-between;
    }
    .panel-md-flex .panel-body
    {
        width: 79%;
        padding: 0 20px;
    }
    .panel-md-flex .panel-body .pre-test
    {
        float: left;

        margin-top: 21px;
    }
    .panel-md-flex .panel-body .text-right
    {
        float: right;

        margin-top: 15px;
    }
    .video-holder .hipervideo
    {
        top: 127px;
        right: 26px;
    }
    .video-holder .hipervideo .hiperlink
    {
        width: 157px;
        height: 118px;
    }
    #modal-pre-test .modal-content {
        width: 900px;
    }
    .info-course-holder .img-course
    {
        height: 281px;

        background-position: center center;
    }
    .panel-info-course > .panel-body .descr-course
    {
        width: 54%;
    }
    .panel-info-course > .panel-body .info-course
    {
        width: 46%;
        padding-bottom: 5px;
    }
    .panel-item-course .info-course
    {
        width: 60%;
    }
    .panel-flex
    {
        display: flex;
    }
    .panel-flex .panel-body
    {
        padding: 0 20px;
    }
    .panel-flex .panel-body .pre-test
    {
        float: left;

        margin: 16px 0 0 30px;
    }
    .final-evaluation-holder .panel-evaluate-certificate .info-area p.pre-test
    {
        flex-basis: 70%;
        position: relative;
        left: -20px;
        float: left;
    }
    .final-evaluation-holder .info-area > .text-right
    {
        display: inline-block;
    }
    .panel-flex .info-area
    {
        margin-left: 10px;
    }
    .panel-flex .info-area .text-right
    {
        margin-top: 0px;
        margin-left: 0px;
    }
    .certificate-holder
    {
        margin-bottom: 40px;
    }
    .certificate-holder .panel-flex
    {
        justify-content: space-between;
    }
    .certificate-holder .panel-flex .info-area .text-right .btn
    {
        margin-top: 10px;
        margin-bottom: 9px;
    }
    .certificate-holder .panel-flex .info-area
    {
        width: 80%;
        margin-left: 0;
    }
    .panel-flex .panel-body .pre-test
    {
        margin-left: 10px;
    }
    .modal-dialog
    {
        width: 900px;
    }
    .modal .modal-header > .modal-info
    {
        float: right;

        margin-top: -20px;
    }
    .modal-info-time
    {
        display: flex;
    }
    .button-area-info .text-close-modal
    {
        margin-top: 6px;
        margin-right: 20px;
    }
    .modal .modal-content .modal-header .modal-info .panel-title
    {
        margin-top: 6px;
    }
    .flexbox-area
    {
        display: inline-block;

        width: 100%;
    }
    .flexbox-area .btn
    {
        float: left;
    }
    .flexbox-area .progress-exercise
    {
        float: left;

        width: 63%;
        margin-left: 120px;

        text-align: center;
    }
    .flexbox-area .test-next-end
    {
        float: right;

        text-align: right;
    }
    .drag-holder
    {
        display: inline-block;

        width: 100%;
    }
    .drag-holder > .drag-item
    {
        display: inline-block;

        margin: 0 10px;
    }
    .title-area-exercise
    {
        display: none;
    }
    .questions-holder
    {
        float: left;

        width: 47%;
        margin-right: 50px;
    }
    .answers-holder
    {
        float: left;

        width: 47%;
    }
    .connect-aux
    {
        top: 0;
        right: -1px;
        bottom: 0;
        left: auto;

        width: 10px;
        height: 20px;
        margin: auto 0;

        border-right: 0;
        border-bottom: 1px solid;
        border-radius: 25px 0 0 25px;
    }
    .white-space
    {
        right: -3px;
        bottom: 0;

        width: 4px;
        height: 18px;
    }
    .question-connect
    {
        padding-bottom: 10px;
    }
    .connect-top
    {
        left: -10px;
    }
    .question-connect-active
    {
        border-right: 0;
        border-radius: 3px 0 0 3px;
    }
    .answer-connect-inactive
    {
        position: relative;
        right: 52px;

        display: inline-block;

        border-left: 0;
        border-radius: 0 3px 3px 0;
    }
    .answer-connect-inactive .connect-top
    {
        display: none;
    }
    .panel-flex .panel-body .evaluate-margin-left
    {
        margin-left: 0;
    }
    .accordion > .position-panel.panel-chapter
    {
        top: -63px;
        left: auto;

        width: 400px;
        max-height: 230px;
    }
    .position-panel
    {
        left: -90px;
    }
    .action-holder .panel-group .panel:nth-child(2) .position-panel
    {
        left: 0;
    }
    .panel-accordion > .panel
    {
        border-radius: 5px !important;
    }
    .action-holder .panel-accordion .panel
    {
        border-radius: 5px 5px 0 0 !important;
    }
    .modal .modal-header > .modal-info-time
    {
        margin-top: -28px;
    }
    .modal .evaluate-header > .modal-info
    {
        margin-top: -21px;
    }
    .video-holder > iframe
    {
        height: 585px;
    }
    .video-holder > iframe.scorm-course {
        height: 710px;
    }
    .action-holder
    {
        clear: both;
        min-height: 147px;
    }
    .action-holder .panel-group
    {
        min-height: 147px;
    }
    .action-holder .panel-group .position-panel:first-child
    {
        top: -110px;
    }
    .video-holder .descr-video
    {
        float: left;
        min-height: 121px;
        width: 55%;
    }
    .item-practice-holder .panel .info-area
    {
        width: 79%;
    }
    .evaluate-unterminated .info-area
    {
        width: 79%;
    }
    .panel-item-course .img-course
    {
        width: 350px;
        height: 197px;

        background-position: center center;
    }
    .info-course .text-right
    {
        position: absolute;
        bottom: 20px;
        padding-right: 22px;
        width: 98.3%;
    }
    .panel-info-course > .panel-body .info-course > p:nth-child(2)
    {
        overflow: hidden;

        width: 100%;

        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .final-evaluation-holder .panel-flex.panel-bordered-danger .info-area
    {
        margin-left: 46px;
    }
    .panel-evaluate-certificate .info-area
    {
        margin-left: 0px;
    }
    .pre-test-holder .panel-bordered-success.panel .info-area,
    .pre-test-holder .panel-bordered-danger.panel .info-area
    {
        height: 60px;
        margin-left: 87px;
    }
    .pre-test-holder .panel-bordered-danger .info-area
    {
        margin-left: 87px;
    }
    #modal-evaluate .modal-content {
        width: 900px;
    }
    .event-gallery .event-image-list li.event-image
    {
        flex-basis: 23.5%;
    }
}

@media screen and (min-width: 992px) {
    .comvc .menu {
        margin-bottom: 0;
    }
    .panel-info-course {
        height: 332px;
    }
}

@media screen and (min-width: 1024px)
{

    .panel-info-course
    {
        height: 282px;
    }
    .panel-body > .info-course {
        height: 200px;
        width: calc(100% - 380px);
    }
    .info-course .text-right .course-details-required
    {
        margin-top: 0;
    }
    .info-course {
        position: relative;
        .text-right {
            position: absolute;
            bottom: 0;
            right: 0;
            padding-right: 0;
        }
    }
    .caps-list .chapter-name
    {
        width: 285px;
    }
    .item-reaction-holder .panel .panel-body
    {
        width: 79%;
        padding-left: 18px;
    }
    .ead-page .video-holder .modal
    {
        width: 900px;
    }
    .ead-page .information-area .tags-area
    {
        width: 49%;
        margin-bottom: 55px;
        float: left;
    }
    .ead-page .information-area .last-course
    {
        float: right;
        width: 34%;
        padding-left: 20px;
    }
    .ead-page .information-area .uc-course .uc-title
    {
        margin-bottom: 45px;
    }
    .ead-page .search-area
    {
        display: block;
        width: 480px;
        left: 240px;
        right: 0;
    }
    .search-results
    {
        border-top: 1px solid #F1F1F1;
        top: -1px;
        z-index: 10;
    }
    .search-area-desktop .custom-search-form .search-results
    {
        width: 585px;
        top: auto;
    }
    .ead-page .search-area .search-icon
    {
        top: 3px;
        font-size: 18px;
    }
    .ead-page .select-holder .select-role
    {
        width: 180px;
    }
    .ead-page .modal-exercise
    {
        margin: auto;
    }
    #modal-pre-test .modal-content {
        width: 900px;
    }
    #modal-evaluate .modal-content {
        width: 900px;
    }
}

@media screen and (min-width: 1200px)
{
    .content-area
    {
        .area-banner
        {
            .banners-slider
            {
                padding-right: 0;
                img
                {
                    width: 100%;
                }
                .owl-item
                {
                    img
                    {
                        width: 100%;
                        padding-right: 5px;
                    }
                }
            }
        }
    }
    .event-gallery .event-image-list li.event-image
    {
        flex-basis: 23.75%;
    }
    .container-fluid
    {
        max-width: 1170px;
    }

    #modal-evaluate .modal-content {
        width: 900px;
    }
    #modal-pre-test .modal-content {
        width: 900px;
    }
    .item-reaction-holder .panel .panel-body
    {
        width: 82%;
    }
    .modal.modal-exercise
    {
        overflow-y: hidden;
    }
    .ead-page .modal-exercise
    {
        margin: auto;
    }
    .ead-page .information-area .tags-area
    {
        float: left;

        width: 50%;
        margin-bottom: 20px;
        padding-bottom: 2px;
    }
    .ead-page .information-area .status-course
    {
        width: 100%;
        padding-left: 20px;
    }
    .ead-page .information-area .status-course > * {
        display: inline-block;
        margin-right: 15px;
    }
    .ead-page .information-area .status-course > *:last-child {
        margin-right: 5px;
    }
    .ead-page .information-area .status-course h2 {
        text-transform: uppercase;
    }
    .ead-page .courses-done
    {
        margin-bottom: 13px;
    }
    .ead-page .courses-approved
    {
        margin-bottom: 13px;
    }
    .ead-page .courses-disapproved
    {
        margin-bottom: 15px;
    }

    .ead-page.comvc-page .information-area .status-course
    {
        width: auto;
        padding-left: 0px;
    }
    .ead-page.comvc-page .information-area .status-course > * {
        display: block;
        margin-right: 0px;
    }
    .ead-page.comvc-page .information-area .status-course > *:last-child {
        margin-right: 0;
    }
    .ead-page.comvc-page .information-area .status-course h2 {
        text-transform: uppercase;
    }
    .ead-page.comvc-page .courses-done
    {
        margin-bottom: 4px;
    }
    .ead-page.comvc-page .courses-approved
    {
        margin-bottom: 4px;
    }
    .ead-page.comvc-page .courses-disapproved
    {
        margin-bottom: 0px;
    }
    .ead-page .information-area .uc-course .uc-title
    {
        line-height: 20px;

        margin-bottom: 14px;
    }
    .ead-page .information-area .last-course
    {
        width: 35%;
    }
    .ead-page .header-elements:before
    {
        width: 2000px;
    }
    .ead-page .header-elements:after
    {
        width: 2000px;
    }
    .ead-page .banners-slider {
        padding-right: 8px;
    }
    .ead-page .area-banner .banner
    {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 1200px)
{
    .header-holder .header-elements .nav-holder
    {
        position: relative;
    }
    .header-holder .menu-area
    {
        right: 0;
        width: 925px;
        top: -68px;
    }
    .ead-page .search-area
    {
        left: 300px;
        top: -61px;
        width: 568px;
    }
    .ead-page .header-holder .name-holder
    {
        top: -88px;
    }
    .accordion > .position-panel.panel-chapter
    {
        top: -90px;
    }
    .video-holder .descr-video
    {
        width: 63%;
    }
    .panel-md-flex .panel-body
    {
        width: 82%;
    }
    .video-holder .hipervideo
    {
        top: 0px;
        right: 0px;
    }
    .video-holder .hipervideo .hiperlink
    {
        width: 300px;
        height: 270px;
    }
    .panel-info-course
    {
        height: 263px;
    }
    .holder-chapters
    {
        max-height: 140px;
    }
    .action-holder .panel-group
    {
        margin-bottom: 95px;
    }
    .panel-info-course > .panel-body .descr-course
    {
        width: 45%;
    }
    .panel-info-course > .panel-body .info-course
    {
        float: right;

        width: 50%;
    }
    .panel-lg-flex
    {
        display: flex;

        justify-content: space-between;
    }
    .panel-lg-flex .info-area
    {
        padding: 0 20px;
    }
    .panel-lg-flex .info-area .pre-test
    {
        float: left;

        margin-top: 10px;
        margin-bottom: 0;
    }
    .panel-lg-flex .info-area .text-right
    {
        float: left;

        margin-top: 10px;
        margin-left: 100px;
    }
    .panel-flex .info-area
    {
        width: 80%;

        text-align: left;
    }
    .panel-flex .panel-body .pre-test
    {
        float: none;

        margin-right: 0;
        margin-left: 0;
    }
    .panel-item-course .info-course
    {
        width: 66.3%;
    }
    .final-evaluation-holder .panel-opacity .panel-body .text-default
    {
        float: left;

        margin-right: 41px;
        margin-left: 260px;
    }
    .panel-evaluate-certificate .title-area
    {
        width: 15%;
    }
    .panel-evaluate-certificate .info-area
    {
        width: calc(100% - 192px);
        margin-left: 0;
    }
    .pre-test-holder .panel-title
    {
        float: left;
    }
    .pre-test-holder .panel-bordered-success.panel .info-area
    {
        margin-left: 96px;
    }
    .pre-test-holder .pre-test
    {
        float: left;

        margin-bottom: 0;
    }
    .pre-test-holder .text-right
    {
        margin-top: -5px;
    }
    .certificate-holder .panel-flex .panel-body .pre-test
    {
        float: left;

        margin-left: 8px;
    }
    .certificate-holder .panel-flex .info-area .text-right
    {
        float: right;
        margin-top: 10px;
    }
    .certificate-holder .panel-flex .info-area .text-right .btn
    {
        margin-top: 0;
        margin-bottom: 0;
    }
    .panel .panel-body.info-area .evaluate-reproved
    {
        margin-right: 110px;
    }
    .final-evaluation-holder .panel-flex.panel-bordered-danger .info-area
    {
        width: 100%;
        margin-left: 53px;
    }
    .video-holder > iframe
    {
        height: 475px;
    }
    .video-holder > iframe.scorm-course {
        height: 870px;
    }
    .item-practice-holder .panel .info-area
    {
        width: 82%;
    }
    .video-holder:after
    {
        height: 215px;
    }
    .certificate-holder .panel-flex .info-area
    {
        width: 82.8%;
    }
    .info-course-holder .img-course
    {
        height: 262px;
    }
    .panel-flex .panel-body .evaluate-margin-left
    {
        float: left;

        width: 77%;
    }
    .pre-test-holder .panel-bordered-danger .info-area
    {
        margin-left: 96px;
    }
    .final-evaluation-holder .panel-bordered-success.panel-icon.panel-flex.evaluate-unterminated .info-area
    {
        margin-left: 0;
        padding-left: 0;
    }
}

@media all and (min-width: 1200px)
{
    .pre-test-completed
    {
        float: left !important;
    }
}

@media all and (max-width: 1200px)
{
    div.last-access {
        top: 60px;
        padding-right: 10px;
    }
}

@media all and (min-width: 991px)
{
    #detailed-pre-test-results .modal-dialog
    {
        width: 991px;
    }

    .modal-dialog
    {
        width: auto;
    }
}

@media all and (max-width: 990px)
{
    .test-results .mobile
    {
        display: block;
    }

    .test-results .desktop
    {
        display: none;
    }

    .test-results .no-padding
    {
        padding: 0 !important;
    }
}

@import 'ead-site/style';

body
{
    &.comvc-page
    {
        color: #000;
        h1, h2, h3, h4, h5, h6
        {
            color: #000;
        }
        p, span
        {
            color: #000;
        }

        .descr-video
        {
            p, span
            {
                color: #FFF;
            }
        }
    }
}

.pagination {
    li > a, li > span {
        margin: 0 6px;
        padding: 7px 14px;
        border-radius: 3px !important;
    }
    li > a:hover {
        border-color: #ef5350;
        color: #ef5350;
        box-shadow: inset 0 0 1px #ef5350;
    }
    > .active > span{
        background-color: #ee4a47;
        border-color: #ef5350;
    }
    > .active:hover > span{
        background-color: #ee525e;
        border-color: #ee525e;
    }
}

.modal-approve-ucs{
    .modal-dialog{
        max-width: 700px;
        .modal-content{
            border-radius: 10px;
        }
        .modal-body{
            img{
                max-width: 100%;
                max-height: 700px;
            }
        }
    }
}

.accordion-cards-home{
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    max-height: 690px;
    overflow: hidden;
    transition: max-height 2.5s;
    
    &.active{
        max-height: 40000px !important;
    }
    
    @media screen and (min-width: 768px){
        max-height: 375px;
        transition: max-height 2s;
        
        &.has-featured {
          max-height: 360px;
          .featured .card {
            .uc-title {
              font-size: 22px;
              line-height: 1.5;
            }
            .img-card-holder {
              padding-right: 0;
            }
            .card-info-holder {
              padding-left: 0;
              position: relative;
              height: 274px;
              .card-info {
                position: absolute;
                bottom: 0;
                width: calc(100% - 15px);
              }
            }
            .img-card {
              a {
                font-size: 0;
                img {
                  max-height: 274px;
                }
              }
            }
          }
        }
        &.active{
            max-height: 20000px !important;
        }
    }

    @media screen and (min-width: 1024px) {
        max-height: 347px;
        transition: max-height 1.3s;
        &.has-featured {
          max-height: 465px;
          .featured .card {
            .card-info-holder {
              height: 375px;
              .card-title .uc-title {
                height: 130px;
              }
            }
            .img-card {
              a {
                img {
                  max-height: 375px;
                }
              }
            }
            .card-info {
              .link-uc.uc-unlocked {
                font-size: 16px;
              }
              .icon-right a span i {
                font-size: 16px;
              }
            }
          }
        }
        &.active{
            max-height: 10000px !important;
        }
    }
    
    @media screen and (min-width: 1366px) {
      &.has-featured {
        max-height: 525px;
        .featured .card {
          .card-info-holder {
            height: 440px;
            .card-description {
              font-size: 14px;
            }
          }
          .img-card {
            a {
              img {
                max-height: 440px;
              }
            }
          }
        }
      }
    }

    .card img{
        max-height: 192px;
        object-fit: cover;
    }

    &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 62px;
        background-color: #f5f5f5;
    }
    .holder-btn-action{
        position: absolute;
        bottom: 14px;
        left: 0;
        display: flex;
        justify-content: center;
        z-index: 1;
        width: 100%;
    }
    .btn-action-home{
        color: white;
        border-color: #04A8A8;
        background-color: #04A8A8;
        transition: 0.3s;
        &:hover{
            background-color: #197e7e;
            border-color: #197e7e;
            color: white;
        }
    }
}

.btn-success {
	color: white;
	border-color: #04A8A8;
	background-color: #04A8A8;
	transition: 0.3s;
	&:hover{
		background-color: #197e7e;
		border-color: #197e7e;
		color: white;
	}
}

@media screen and (max-width: 500px) {
    .panel .text-right > .btn {
        width: 100%;
    }
}
// .bg-white{
//     background-color: #F5F5F5 !important;
// }
// 
// .bg-hnt-yellow{
//     background-color: #FAA61A !important;
// }
// 
// .text-white{
//     color: #F5F5F5;
// }

.login-form-holder {
    background: white;
    border-top: 5px solid #04A8A8;
    padding: 40px 20px 20px;
}

.login-container {
    background: url('../images/login-bg-mobile.jpg') no-repeat center top / cover;
    @media screen and (min-width: 768px) {
        background: url('../images/login-bg.jpg') no-repeat left top / cover;
    }
}
.card-title-article {
    .article-title {
        text-overflow: ellipsis;
        overflow: hidden; 

        font-size: 14px;
        font-weight: 600;
        padding: 5px 0;
        color: #5B422E;
    }
    .date {
        font-size: 1rem;
        color: black;
        padding: 10px 0;
    }
}

.footer-info.fix, .card-title-article {
    padding: 6px 10px;
}

.article-container {
	iframe {
		max-width: 100%;
	}

	.video {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		max-width: 100%;

		iframe, object, embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
    .article-image {
        padding: 20px 10%;

        .img-article {
            height: 250px;
            width: 100%;
            object-fit: contain;
        }
    }
    .article-content {
		margin: 20px 0;
		max-width: 100%;
    }

    .article-date {
        font-size: 14px;
        color: black;
        padding: 10px 0;
        margin: 10px 0;
        text-align: center;
    }
    .title {
        word-wrap: break-word;
	}
	.article-title {
		height: 55px;
	}
}
.modal-dialog {
	overflow-y: scroll;
	.modal-content {
		overflow-y: scroll;
	}
}

.login-container{ 
	.panel > .panel-heading {
		display: flex;
		justify-content: center;
	}
}