// @mixin reset()
// {
// 	margin: 0;
// 	padding: 0;
// 	vertical-align: baseline;
// }

// @mixin font-brand()
// {
// 	font-family: "Roboto Condensed", sans-serif;
// 	font-weight: 400;
// }

@mixin clearfix()
{
	content: '';
	clear: both;
	display: table;
}

@mixin font-alt()
{
	font-family: 'Exo', sans-serif;
	font-weight: 400;
}

@mixin container-fluid()
{
  	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}

@mixin logo()
{
	display: inline-block;
	width: 212px;
	height: 109px;
	background: url('../../src/images/logo.png') no-repeat;
	background-size: contain;
	text-indent: -9999px;
}