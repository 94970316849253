.text
{
    color: #888;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;

    &.text-modal
    {
        color: #9B9B9B;
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 20px;
        white-space: pre-line;
    }

    &.text-term
    {
        font-size: 10px;
        color: #666;
        line-height: 17px;
        position: absolute;
        bottom: 0px;
    }
    
    @media screen
    {
        @media (min-width: 768px)
        {
            &.text-term
            {
                padding-left: 10px;
                width: 250px;
            }
        }
        
        @media (min-width: 1024px)
        {
            &.text-term
            {
                width: 450px;
            }
        }

        @media (min-width: 1280px)
        {
            &.text-term
            {
                width: auto;
            }
        }
    }
}

.title
{
    &.title-description
    {
        color: #544334;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        margin: 0 0 2px;

        .see-info
        {
            margin-left: 5px;
            color: #544334;
            display: inline-block;
            position: relative;
            top: 1px;
            cursor: pointer;

            a
            {
                color: #544334;
            }
        }
    }
}

a
{
    font-size: 14px;
    color: #FFF;
    line-height: 19px;
    font-weight: 300;
}