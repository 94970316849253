.training-category-preview
{
    & + .row
    {
        margin-bottom: 50px;
    }
}

.preview-info-content
{
    .sprout-holder
    {
        width: 82px;
        height: 82px;
        margin: 0 auto 30px;
        background-image: url('../../images/sprout-image.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .text-holder
    {
        p
        {
            color: #949494;
            font-size: 14px;
            line-height: 22px;
            margin-bottom: 0px;

            span
            {
                text-decoration: underline;
                font-weight: 700;
            }
        }
    }

    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;

        .sprout-holder
        {
            margin: 0 30px 0 0;
        }
    }
}