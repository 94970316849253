#podcast-app
{
	background-color: #000;

	.page-spacing
	{
		padding: 30px 15px;
	}

	.row
	{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;

		> .card-playlist-holder
		{
			flex: 0 0 100%;
			max-width: 100%;
			margin-bottom: 30px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	@media screen
	{
		@media (min-width: 768px)
		{
			.page-spacing
			{
				padding: 80px 15px;
			}

			.row
			{
				> .card-playlist-holder
				{
					flex: 0 0 33.3333333%;
					max-width: 33.3333333%;
				}
			}
		}

		@media (min-width: 1024px)
		{
			.row
			{
				> .card-playlist-holder
				{
					flex: 0 0 20%;
					max-width: 20%;
				}
			}
		}
	}
}

.card-playlist
{
	display: block;

	> .image-holder
	{
		margin-bottom: 16px;
		height: 210px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	> .title-card-playlist
	{
		text-align: center;
		font-size: 18px;
		font-weight: 700;
		color: #FFF;
	}
}

.switch-view-podcasts
{
	margin-bottom: 30px;

	ul
	{
		text-align: center;

		li
		{
			display: inline-block;
			position: relative;
			margin: 0 20px;

			a
			{
				color: #FFF;
				font-size: 16px;
				font-weight: 700;
			}

			&:after
			{
				content: '';
				display: block;
				height: 8px;
				width: 75%;
				margin: 3px auto 0;
				background-color: #000;
			}

			&.active
			{
				&:after
				{
					background-color: #04A8A8;
				}
			}
		}
	}

	@media screen
	{
		@media (min-width: 768px)
		{
			margin-bottom: 120px;
		}
	}
}

.entry-page-playlist
{
	@media screen
	{
		@media (min-width: 768px)
		{
			&.page-spacing
			{
				padding-bottom: 0px !important;
			}

			.switch-view-podcasts
			{
				margin-bottom: 50px;
			}
		}
	}
}

.playlist-wrapper
{
	display: flex;
	flex-wrap: wrap;
}

.sidebar-playlist
{
	flex: 0 0 100%;
	max-width: 100%;
	margin-bottom: 40px;

	> .image-holder
	{
		margin-bottom: 15px;
		height: 200px;
		text-align: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	> .info-holder
	{
		margin-bottom: 40px;

		.title-playlist
		{
			font-size: 30px;
			text-align: center;
			margin-bottom: 20px;
			color: #FFF;
		}

		.podcast-amount
		{
			text-align: center;
			display: block;
			font-size: 24px;
			color: #ADADAD;
		}
	}

	> .action-button
	{
		.button
		{
		    text-transform: uppercase;
			color: #FFF;
			font-size: 30px;
			font-weight: 400;
			background-color: #32a8a8;
			border-radius: 20px;
			padding: 30px 0;
			display: block;
			max-width: 255px;
			margin: 0 auto;
			text-align: center;
			transition: background, color .4s ease-in-out;

			&:hover,
			&:active
			{
				background-color: #FFF;
				color: #D8001E;
			}

			&.audio-loading
			{
				font-size: 0;
				position: relative;

				&:before
				{
					content: '';
					display: block;
					margin: auto;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					height: 50px;
					width: 50px;
					background-size: cover;
					background-image: url('../../images/loading.gif');
					background-repeat: no-repeat;
					background-position: center;
				}
			}
		}
	}

	@media screen
	{
		@media (min-width: 1024px)
		{
			flex: 0 0 30%;
			max-width: 30%;
		}

		@media (min-width: 1280px)
		{
			flex: 0 0 34%;
			max-width: 34%;

			> .image-holder
			{
				height: 398px;

				img
				{
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}

.content-playlist
{
	flex: 0 0 100%;
	max-width: 100%;

	@media screen
	{
		@media (min-width: 1024px)
		{
			padding-left: 57px;
			flex: 0 0 70%;
			max-width: 70%;
		}

		@media (min-width: 1280px)
		{
			padding-left: 80px;
			flex: 0 0 66%;
			max-width: 66%;
		}
	}
}

.audio-podcast-wrapper
{
	position: relative;

	.favourite-podcast
	{
		height: 23px;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    margin: auto 0;
	    right: 15px;

		i
		{
			font-size: 20px;
			color: #AEAEAE;
		}

		&.has-favourited,
		&:hover,
		&:active
		{
			i
			{
				color: #FFC600;

				&:before
				{
					content: '\e906';
				}
			}
		}

		&.has-favourited
		{
			&:hover,
			&:active
			{
				i
				{
					color: #AEAEAE;

					&:before
					{
						content: '\e907';
					}
				}
			}
		}
	}

	@media screen
	{
		@media (min-width: 768px)
		{
			.favourite-podcast
			{
				height: 31px;
				right: 20px;

				i
				{
					font-size: 29px;
				}
			}
		}
	}
}

.podcast-item
{
	padding: 15px 10px;
	display: flex;
	align-items: center;
	cursor: pointer;
	background-color: #000;
	transition: all .4s ease-in-out;

	.icon-holder
	{
		align-self: center;
		display: none;

		i
		{
			color: #FFF;
			transition: color .4s ease-in-out;
		}
	}

	.info-holder
	{
		flex: 1 1 100%;
		max-width: 100%;

		.title,
		.text
		{
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 245px;
			transition: all .4s ease-in-out;
		}

		.title
		{
			color: #FFF;
			font-size: 16px;
			font-weight: 700;
			margin: 0 0 4px;
		}

		.text
		{
			display: block;
			font-size: 12px;
			font-weight: 400;
			color: #AEAEAE;
		}
	}

	.podcast-duration
	{
		display: none;
		color: #FFF;
		transition: color .4s ease-in-out;
	}

	&.podcast-active
	{
		background-color: #280F0F;

		.icon-holder
		{
			i
			{
				color: #D8001E;

				&:before
				{
					content: '\e909';
				}
			}
		}

		.info-holder
		{
			.title
			{
				color: #D8001E;
			}

			.text
			{
				color: #FFF;
			}
		}

		.podcast-duration
		{
			color: #D8001E;
		}

		&:hover,
		&:active
		{
			.icon-holder
			{
				i
				{
					&:before
					{
						content: '\e909';
					}
				}
			}
		}
	}

	&.podcast-pausable
	{
		.icon-holder
		{
			i
			{
				&:before
				{
					content: '\e903';
				}
			}
		}

		&:hover,
		&:active
		{
			.icon-holder
			{
				i
				{
					&:before
					{
						content: '\e903';
					}
				}
			}
		}
	}

	&.podcast-loading
	{
		.icon-holder
		{
			height: 38px;
			background-size: cover;
			background-image: url('../../images/loading.gif');
			background-repeat: no-repeat;
			background-position: center;

			i
			{
				&:before
				{
					content: '';
				}
			}
		}

		&:hover,
		&:active
		{
			.icon-holder
			{
				i
				{
					&:before
					{
						content: '';
					}
				}
			}
		}
	}

	&:hover,
	&:active
	{
		background-color: #280F0F;

		.icon-holder
		{
			i
			{
				color: #D8001E;

				&:before
				{
					content: '\e903';
				}
			}
		}

		.info-holder
		{
			.title
			{
				color: #D8001E;
			}

			.text
			{
				color: #FFF;
			}
		}

		.podcast-duration
		{
			color: #D8001E;
		}
	}

	@media screen
	{
		@media (min-width: 768px)
		{
			padding: 20px 50px 20px 20px;

			.icon-holder
			{
				display: inline-block;
				flex: 0 0 31px;
				max-width: 31px;
				margin-right: 50px;

				i
				{
					font-size: 38px;
				}
			}

			.info-holder
			{
				.title,
				.text
				{
					max-width: 500px;
				}

				.title
				{
					font-size: 24px;
					margin-bottom: -4px;
				}

				.text
				{
					font-size: 24px;
				}
			}

			.podcast-duration
			{
				display: inline-block;
				font-size: 24px;
				font-weight: 700;
				margin-right: 20px;
			    flex: 0 0 63px;
    			max-width: 63px;
    			text-align: right;
			}

			.podcast-duration,
			.favourite-podcast
			{
				position: relative;
			}

			&.podcast-active
			{
				.icon-holder
				{
					i
					{
						color: #D8001E;
					}
				}

				.podcast-duration
				{
					color: #D8001E;
				}
			}
		}

		@media (min-width: 1024px)
		{
			.info-holder
			{
				padding-right: 30px;

				.title,
				.text
				{
					max-width: 385px;
				}
			}
		}

		@media (min-width: 1280px)
		{
			.info-holder
			{
				.title,
				.text
				{
					max-width: 440px;
				}
			}
		}
	}
}

.player-bar-holder
{
	padding: 30px 0;
	background-color: #171717;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	&.bar-fixed
	{
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
	}

	> .controls-player
	{
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 15px;
		text-align: center;

		li
		{
			display: inline-block;
			margin: 0 20px;
			vertical-align: middle;

			a
			{
				display: inline-block;

				i
				{
					font-size: 34px;
					color: #FFF;
					transition: color .4s ease-in-out;
				}

				&:hover
				{
					i
					{
						color: #32a8a8;
					}
				}
			}

			&.play-pause
			{
				a
				{
					i
					{
						font-size: 59px;
					}
				}

				&.audio-loading
				{
					a
					{
						height: 59px;
						width: 59px;
						background-size: cover;
						background-image: url('../../images/loading.gif');
						background-repeat: no-repeat;
						background-position: center;
						pointer-events: none;

						i
						{
							display: none;
						}
					}
				}
			}
		}
	}

	> .status-player
	{
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0 15px;

		.player-bar
		{
			display: flex;
			align-items: center;

			span
			{
				font-size: 14px;
				color: #AEAEAE;
				text-transform: uppercase;
				white-space: nowrap;

				&:first-child
				{
					margin-right: 20px;
				}

				&:last-child
				{
					margin-left: 20px;
				}
			}

			.timer-podcast
			{
				flex: 1 1 100%;
				max-width: 100%;
				height: 9px;
				position: relative;
                background-color: #216969;
                border-radius: 10px;

				> .timer-bar
				{
					position: absolute;
					top: 0;
					border-radius: 10px;
					background-color: #32a8a8;
					width: 0%;
					height: 100%;
					transition: width .3s ease-in-out;
				}

				> .timer-bar-control
				{
					-webkit-appearance: none;
					width: 100%;
					background: transparent;
					position: relative;
					z-index: 999;
					height: 40px;
					top: -16px;

					&::-webkit-slider-thumb
					{
						-webkit-appearance: none;
					}

					&:focus
					{
						outline: none;
					}

					&::-ms-track
					{
						width: 100%;
					  	cursor: pointer;
						background: transparent; 
						border-color: transparent;
						color: transparent;
					}

					&::-webkit-slider-thumb
					{
						-webkit-appearance: none;
						height: 11px;
						width: 11px;
						border-radius: 50%;
						background: #AEAEAE;
						cursor: pointer;
						margin-left: -2px;
						margin-top: -1px;
					}

					&::-moz-range-thumb
					{
						height: 11px;
						width: 11px;
						border-radius: 50%;
						background: #AEAEAE;
						cursor: pointer;
					}

					&::-ms-thumb
					{
						height: 11px;
						width: 11px;
						border-radius: 50%;
						background: #AEAEAE;
						cursor: pointer;
					}

					&::-webkit-slider-runnable-track
					{
						width: 100%;
						height: 9px;
						cursor: pointer;
						background: transparent;
						border-radius: 10px;
					}

					&::-moz-range-track
					{
						width: 100%;
						height: 9px;
						cursor: pointer;
						background: transparent;
						border-radius: 10px;
					}

					&::-ms-track
					{
						width: 100%;
						height: 9px;
						border-radius: 10px;
						cursor: pointer;
						background: transparent;
						color: transparent;
					}

					&:focus::-webkit-slider-runnable-track
					{
						background: transparent;
					}
				}
			}
		}
	}

	@media screen
	{
		@media (min-width: 768px)
		{
			> .controls-player
			{
				li
				{
					margin: 0 35px;
				}
			}

			> .status-player
			{
				flex: 0 0 100%;
				max-width: 620px;
				padding: 0;
			}
		}
	}
}