@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.button-track
{
    &.button-large
    {
        font-size: 12px;
        padding: 19px 20px 19px 62px;
        line-height: 22px;
        border-radius: 30px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .5);
        display: block;

        &.button-icon
        {
            > i
            {
                font-size: 34px;
                color: #FFF;
                position: absolute;
                left: 7px;
                top: 5px;
                z-index: 10;
            }
        }
    }

    &.button-jobtitle
    {
        background-color: #076640;
        color: #FFF;
        border: 1px solid transparent;
        position: absolute;
        right: 15px;
        bottom: 82px;
        z-index: 10;
        padding: 10px 15px 10px 45px;
        text-align: right;
        font-size: 12px;
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-name: bounceIn;
        animation-name: bounceIn;

        &.button-icon
        {
            > i
            {
                font-size: 30px;
                top: 5px;
                left: 10px;
            }
        }
    }

    &.button-jobtitle-disabled
    {
        background-color: rgba(7, 102, 64, 0.55) !important;
        border-color: transparent !important;
    }

    &.button-open-modal
    {
        cursor: pointer;
    }

    @media screen
    {
        @media (min-width: 410px)
        {
            &.button-large
            {
                &.button-icon
                {
                    > i
                    {
                        left: 10px;
                    }
                }
            }
        }
        
        @media (min-width: $screen-sm)
        {
            &.button-large
            {
                display: block;
            }

            &.button-jobtitle
            {
                font-size: 16px;
                right: 15px;
                bottom: 11px;

                &.button-icon
                {
                    > i
                    {}
                }
            }
        }
    }
}