.banner-holder
{
    margin-top: 30px;
    margin-bottom: 30px;

    .banners-slider
    {
        .banner-image
        {
            img
            {
                width: 100%;
                height: auto;
            }

            .mobile-image
            {
                display: block;
            }

            .tablet-landscape-image
            {
                display: none !important;
            }

            .tablet-portrait-image
            {
                display: none !important;
            }

            .desktop-image
            {
                display: none !important;
            }

            @media screen
            {
                @media (min-width: 768px) and (max-height: 1280px)
                {
                    .mobile-image
                    {
                        display: none !important;
                    }

                    .tablet-landscape-image
                    {
                        display: none !important;
                    }

                    .tablet-portrait-image
                    {
                        display: block !important;
                    }

                    .desktop-image
                    {
                        display: none !important;
                    }
                }

                @media (min-width: 1024px) and (max-height: 860px)
                {
                    .mobile-image
                    {
                        display: none !important;
                    }

                    .tablet-landscape-image
                    {
                        display: block !important;
                    }

                    .tablet-portrait-image
                    {
                        display: none !important;
                    }

                    .desktop-image
                    {
                        display: none !important;
                    }
                }
                
                @media (min-width: 1280px)
                {
                    .mobile-image
                    {
                        display: none !important;
                    }

                    .tablet-landscape-image
                    {
                        display: none !important;
                    }

                    .tablet-portrait-image
                    {
                        display: none !important;
                    }

                    .desktop-image
                    {
                        display: block !important;
                    }
                }
            }
        }
    }

    .banner-track
    {
        display: block;
        background: transparent;
        background: -moz-linear-gradient(top, transparent 25%, rgba(235,229,208,1) 100%);
        background: -webkit-linear-gradient(top, transparent 25%,rgba(235,229,208,1) 100%);
        background: linear-gradient(to bottom, transparent 25%,rgba(235,229,208,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='#ebe5d0',GradientType=0 );
        padding: 21px 45px;
    }

    .logo-tracks
    {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../images/logo-trilhas-large.png');
        width: 100%;
        max-width: 211px;
        min-height: 120px;
        height: 140px;
        max-height: 88px;
        margin: 0 auto 48px;
    }

    .info-banner
    {
        text-align: center;

        p
        {
            font-size: 12px;
            color: #6C6C6C;
            line-height: 19px;
            margin-bottom: 25px;
        }

        .button
        {
            background-color: transparent;
            border-color: #076A42;
            color: #076A42;
            border-radius: 26px;
            line-height: 33px;
            font-size: 18px;
            text-align: center;
            display: inline-block;
            padding: 3px 30px;
            text-transform: uppercase;
        }
    }

    @media screen
    {
        @media (min-width: 768px)
        {
            .banner-track
            {
                display: flex;
                align-items: center;
                padding: 21px 45px;
            }

            .logo-tracks
            {
                margin: 0 50px 0 0;
            }

            .info-banner
            {
                text-align: left;

                p {
                    float: left;
                    margin-right: 50px;
                }

                .button {
                    margin-bottom: 11px;
                }
            }
        }

        @media (min-width: 1024px)
        {
            .banner-track
            {
                position: relative;
            }

            .info-banner
            {
                p
                {
                    margin-bottom: 0px;
                }

                .button
                {
                    margin-bottom: 0px;
                    position: relative;
                    top: 9px;
                }
            }

            .tree-tracks
            {
                background-image: url('../../images/tree-trilhas.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 220px;
                height: 140px;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        @media (min-width: 1280px)
        {
            .tree-tracks
            {
                width: 272px;
                height: 160px;
                right: 0;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .banner-holder .logo-tracks {
        min-height: 58px !important;
        height: 58px !important;
        max-height: 58px !important;
        margin: 0 auto 10px !important;
    }
    .banner-holder .info-banner p {
        font-size: 10px !important;
        margin-bottom: 10px !important;
    }
    .banner-holder .info-banner .button {
        padding: 3px 15px !important;
        line-height: 20px !important;
        font-size: 13px !important  ;
    }
}