.modal {
    position: fixed;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s ease-in-out;

    background-color: rgba(235, 229, 208,.8);

    &:before
    {
        display: none;
    }

    .layer-close-modal
    {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .modal-holder {
        margin: 0 20px;
        border-radius: 15px;
        max-width: 390px;
        width: auto;
        background-color: white;
        border: 1px solid #544334;
        transform: translateY(0%);
        transition: all .45s ease-in-out;
    }

    .modal-content {
        padding: 20px;
        border-radius: 15px;
        
        .modal-body {
            padding: 0;

            .modal-header-info
            {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                > i
                {
                    margin-right: 8px;
                    font-size: 22px;
                    color: #544334;
                }

                > .descr-holder
                {
                    color: #544334;
                    line-height: 18px;
                    text-align: left;
                    font-size: 13px;
                    
                    span
                    {
                        font-weight: 700;
                        display: block;
                    }
                }
            }

            .modal-body-info
            {
                .close-modal
                {
                    color: #544334;
                    font-size: 12px;
                    line-height: 17px;
                    text-decoration: underline;
                    display: block;
                    text-align: center;
                }
            }
        }
    }

    &.modal-use-term
    {
        overflow: hidden;

        .modal-holder
        {
            max-height: 95vh;
            max-width: 600px;

            .modal-content
            {
                max-height: 95vh;
                overflow: auto;

                .modal-body
                {
                    .modal-title
                    {
                        font-size: 20px;
                        color: #076640;
                        margin-bottom: 20px;
                    }

                    .text-container
                    {
                        .text
                        {
                            margin-bottom: 15px;
                        }
                    }

                    .agree-check
                    {
                        .checkbox-holder
                        {
                            text-align: center;
                
                            label
                            {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                
                                span
                                {
                                    text-align: left;
                                    color: #5B422D;
                                    font-size: 16px;
                                    font-weight: 500;
                
                                    &.box-input
                                    {
                                        height: 20px;
                                        width: 20px;
                                        flex: 0 0 20px;
                                        margin-right: 10px;
                                        border: 1px solid #5B422D;
                                        position: relative;
                
                                        &:before
                                        {
                                            content: '';
                                            position: absolute;
                                            top: 2px;
                                            left: 2px;
                                            right: 2px;
                                            bottom: 2px;
                                            background-color: #5B422D;
                                            transform: scale(0);
                                            transition: transform .4s ease;
                                        }
                                    }
                                }
                
                                input 
                                {
                                    &:checked
                                    {
                                        & + .box-input
                                        {
                                            &:before
                                            {
                                                transform: scale(1);
                                            }
                                        }
                                    }
                                }
                            }
                        }                
                    }
                }
            }
        }
    }

    &.modal-tutorial
    {
        .modal-holder
        {
            max-width: 450px;
        }

        .modal-content
        {
            .logo-holder
            {
                width: 135px;
                height: 52px;
                background-image: url('../../images/logo-track.png');
                background-repeat: no-repeat;
                background-position: center;
                margin: 0 auto 30px;
            }

            .text
            {
                font-size: 14px;
                text-align: center;
                margin-bottom: 30px;
            }

            .actions-holder
            {
                .button-tutorial
                {
                    background-color: #d5454a;
                    border-radius: 30px;
                    color: #FFF;
                    border-color: #d5454a;
                    font-size: 16px;
                    display: inline-block;
                    padding: 7px 18px;
                    font-weight: 400;
                }

                .skip-tutorial
                {
                    color: #076640;
                    font-weight: 300;
                    font-size: 12px;
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }

    @media screen
    {
        @media (min-width: 410px)
        {
            .modal-holder
            {
                margin: 0 63px;
            }

            &.modal-use-term
            {
                .modal-holder
                {
                    margin: 0 30px;
                }
            }

            &.modal-tutorial
            {
                .modal-content
                {
                    padding: 20px 40px;
                }
            }
        }
    }
}

.modal-enter
{
    opacity: 1;
}

.modal-leave-active
{
    opacity: 0;
}

.modal-enter .modal-holder,
.modal-leave-active .modal-holder
{
    opacity: 0;
    transform: translateY(-30%);
}