.logo-holder-comvc
{
	margin-bottom: 20px;
	float: left;
	width: 85%;
	h1
	{
		.logo
		{
			@include logo;
		}
	}
}

@media screen and (min-width: 768px) {
	.logo-holder-comvc
	{
		width: 213px;
		margin-top: 33px;
		margin-bottom: 0;
		h1
		{
			margin: 0;
		}
	}
}

@media screen and (min-width: 1024px) {
	.logo-holder-comvc
	{
		margin-top: 0;
		position: absolute;
		top: 33px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
}