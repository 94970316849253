.mobile-menu
{
	display: block !important;
	.menu-close
	{
		display: none;
		background: #DA0020;
		padding: 20px 0;
		.nav-menu
		{
			li
			{
				.menu-item
				{
					margin-bottom: 20px;
					display: block;
					color: #FFF;
					font-size: 16px;
					.icon-submenu
					{
						transform: rotate(0deg);
						float: right;
						transition: transform .3s ease-in-out;
						i
						{
							vertical-align: middle;
						}

						&.submenu-active
						{
							transform: rotate(180deg);
						}
					}
				}
				.submenu
				{
					display: none;
					margin-bottom: 20px;
					padding: 0 15px;
					li
					{
						.submenu-item
						{
							font-size: 14px;
							color: #FFF;
							margin-bottom: 20px;
							display: block;
						}
						&:last-child
						{
							.submenu-item
							{
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
	.menu-alt
	{
		display: flex;
		justify-content: space-around;
		background: #DD0020;
		padding: 15px 0;
		.colmn-50
		{
			flex-basis: 50%;
			text-align: center;
			.menu-item-icon
			{
				color: #FFF;
				font-size: 16px;
				.icon
				{
					background: url('../../images/sprite.png') no-repeat;
					background-position: 0px 0px;
					width: 35px;
					height: 35px;
					margin: 0 auto 5px;
				}
				span
				{
					color: #FFF;
				}
				&.capture
				{
					.icon
					{
						width: 37px;
						height: 37px;
						background-position: -324px -58px;
					}
				}
				&.conference
				{
					.icon
					{
						width: 31px;
						height: 37px;
						background-position: -388px -58px;
					}
				}
			}
		}
	}
}

.desktop-menu
{
	display: none;
	.menu-holder
	{
		background: #DD0020;
		height: 75px;
		.menu-list
		{
			position: relative;
			width: 642px;
			margin: 0 auto;
			.main-menu
			{
				.nav-menu
				{
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 0;
					li
					{
						.menu-item
						{
							position: relative;
							display: inline-block;
							padding: 10px 7px 8px;
							height: 75px;
							transition: background-color .3s ease-in-out;
							.icon-holder
							{
								width: 60px;
								height: 53px;
								margin: 0 8px;
								background: url('../../images/sprite-2.png') no-repeat;
								&.training
								{
									display: inline-block;
								    width: 73px;
	    							background-position: -1px -58px;
								}
								&.podcasts
								{
									display: inline-block;
									width: 51px;
									background-position: -89px -174px;
								}
								&.events
								{
									display: inline-block;
									background-position: -81px -58px;
	    							width: 42px;
								}
								&.data
								{
									display: inline-block;
									background-position: -129px -58px;
									width: 63px;
								}
								&.tools
								{
									display: inline-block;
									background-position: -198px -58px;
									width: 68px;
								}
								&.myoffice
								{
									display: inline-block;
									background-position: -270px -58px;
									width: 49px;
								}
								&.capture
								{
									display: inline-block;
									background-position: -322px -58px;
									width: 42px;
								}
								&.conference
								{
									display: inline-block;
									background-position: -371px -57px;
									width: 64px;
								}
								&.search
								{
									display: inline-block;
									background-position: -440px -57px;
									width: 38px;
								}
								&.search-active
								{
									width: 38px;
								    background-position: -440px 1px;
								}
								&.faq
								{	
									display: inline-block;
									height: 56px;
									width: 24px;
									background-position: -14px -173px;
								}
							}
							&.menu-item-dropdown
							{
								cursor: pointer;
							}
							&.search-on
							{
								background: #FFF;
								padding: 10px 7px 8px;
								&:before
								{
									opacity: 1;
								}
							}
							&:before
							{
								content: '';
								width: 100%;
								height: 8px;
								display: inline-block;
								background: #000;
								position: absolute;
	    						top: -8px;
	    						left: 0;
	    						opacity: 0;
	    						transition: opacity .3s ease-in-out;
	    						pointer-events: none;
							}
							&:hover
							{
								padding: 10px 7px 8px;
								background: #FFF;
								&:before
								{
									opacity: 1;
								}
								.icon-holder
								{
									&.training
									{
									    background-position: -1px 1px;
									}
									&.podcasts
									{
										display: inline-block;
										width: 51px;
										background-position: -147px -174px;
									}
									&.events
									{
									    background-position: -81px 1px;
									}
									&.data
									{
									    background-position: -128px 1px;
									}
									&.tools
									{
									    background-position: -197px 1px;
									}
									&.myoffice
									{
									    background-position: -268px 1px;
									}
									&.capture
									{
									    background-position: -322px 1px;
									}
									&.conference
									{
									    background-position: -371px 1px;
									}
									&.search
									{
									    background-position: -440px 1px;
									}
									&.faq
									{
										background-position: -49px -172px;
									}
								}
							}
						}
						&.submenu-item-open
						{
							position: relative;
							.submenu-desktop
							{
							    opacity: 0;
								transition: all .3s ease-in-out;
							    position: absolute;
							    top: 87px;
							    width: 155px;
							    left: -30px;
							    background: #FFF;
							    padding: 10px;
							    border-radius: 5px;
							    z-index: -1;
							    li
							    {
							    	.submenu-item
							    	{
							    		margin-bottom: 7px;
							    		padding-bottom: 7px;
							    		border-bottom: 1px solid #DD0020;
							    		display: block;
							    		font-size: 14px;
							    		color: #DD0020;
							    	}
							    	&:last-child
							    	{
							    		.submenu-item
							    		{
							    			border-bottom: 0;
							    			padding-bottom: 0;
							    			margin-bottom: 0;
							    		}
							    	}
							    }
								
								&:before
							    {
							    	content: '';
							    	width: 0;
							    	height: 0;
							    	border-bottom: 12px solid #FFF;
							    	border-right: 12px solid transparent;
							    	border-left: 12px solid transparent;
							    	position: absolute;
							    	top: -12px;
							    	left: 0;
							    	right: 0;
							    	margin: auto;
							    	opacity: 0;
							    	transition: opacity .3s ease-in-out;
							    }

							    &.submenu-open
							    {
							    	opacity: 1;
							    	&:before
							    	{
							    		opacity: 1;
							    	}
							    }
							}

							&:hover
						    {
								.submenu-desktop
							    {
							    	display: block;
							    	opacity: 1;
							    	z-index: 10;
							    	&:before
							    	{
							    		opacity: 1;
							    	}
							    }
						    }
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.mobile-menu
	{
		display: none;
	}
	.desktop-menu
	{
		display: block;
	}

	.open-mobile-menu-holder
	{
		display: none;
	}
}

@media screen and (min-width: 1024px) {
	.desktop-menu
	{
		.menu-holder
		{
			.menu-list
			{
				width: 692px;
				.main-menu
				{
					.nav-menu
					{
						li
						{
							.menu-item
							{
								padding: 10px 10px 8px;
								&:hover
								{
									padding: 10px 10px 8px;
								}

								&.search-on
								{
									padding: 10px 10px 8px;
								}
							}
							&.submenu-item-open
							{
								.submenu-desktop
								{
									left: -25px;
								}
							}
						}
					}
				}
			}
		}
	}	
}