.training-category
{
	.category-title
	{
		margin-bottom: 30px;
		background: #03a8a8;
		color: white;
		padding: 10px;
		color: white;
	    padding: 10px;
	    font-size: 18px;
	    margin-top: 0;
	}
}