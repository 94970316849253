.social {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .social-item {
        background-image: url('../../images/sprite.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        width: 51px;
        height: 51px;
        margin: 0 10px;
    }

    .social-item:last-child {
        margin: 0 10px 0 0;
    }

    &.social-small .social-item {
        transform: scale(.8);
    }
    
    .whatsapp {
        background-position: -492px 0;
    }

    .instagram {
        background-position: -545px 0;
    }

    .twitter {
        background-position: -598px 0;
    }
    
    .facebook {
        background-position: -651px 0;
    }

    .linkedin {
        background-position: -704px 0;
    }

    .google-plus {
        background-position: -757px 0;
    }
}