.sidebar-wrapper
{
    opacity: 0;
    transition: opacity .45s ease-in-out;

    .hamburger-mobile
    {
        position: absolute;
        z-index: 18;
        left: 11px;
        top: 30px;
    }

    .sidebar-holder
    {
        display: inline-flex;
        position: absolute;
        transform: translate(-145px);
        transition: transform .3s ease-in-out;
        z-index: 8;
        
        .sidebar-content
        {
            background-color: #544334;
            position: relative;
            z-index: 14;

            .menu-holder
            {
                margin: 114px 0 0 15px;
                opacity: 0;
                transition: all .25s ease-in-out;
                max-width: 130px;

                li
                {
                    margin-bottom: 15px;

                    > a
                    {
                        span
                        {
                            display: block;
                            font-weight: 700;
                        }
                    }
                }
            }
        }

        > a
        {
            position: absolute;
            bottom: 23px;
            left: 15px;
            opacity: 0;
            transition: all .25s ease-in-out;
            z-index: 14;
        }

        &.sidebar-active
        {
            transform: translate(0px);
            z-index: 15;

            .sidebar-content
            {
                .menu-holder
                {
                    opacity: 1;
                }
            }

            > a
            {
                opacity: 1;
            }
        }

        &:after
        {
            content: '';
            display: block;
            width: 50px;
            height: 100vh;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-image: url('../../images/side-career.png');
            // opacity: 0;
            // transform: scaleY(0);
            transform-origin: bottom;
            transition: opacity .5s, transform .8s ease-in-out;
            position: relative;
            left: -1px;
        }

        &.sidebar-loaded
        {
            &:after
            {
                opacity: 1;
                transform: scaleY(1);
            }
        }
    }

    .sidebar-overlay
    {
        z-index: 12;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, .6);
        opacity: 0;
        transition: opacity .3s ease-in-out;
        pointer-events: none;

        &.sidebar-active
        {
            pointer-events: all;
            opacity: 1;
        }
    }

    &.display-sidebar
    {
        opacity: 1;
    }
}