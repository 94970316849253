
// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm}) {
        @content;
    }
}
 
// Medium devices
@mixin md {
    @media (min-width: #{$screen-md}) {
        @content;
    }
}
 
// Large devices
@mixin lg {
    @media (min-width: #{$screen-lg}) {
        @content;
    }
}

// Custom width devices
@mixin rwd-width($width) {
    @media (min-width: $width+'px') {
        @content;
    }
}

// Custom height devices
@mixin rwd-height($height) {
    @media (min-height: $height+'px') {
        @content;
    }
}