.hamburger-mobile
{
  padding: 0px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  > .box
  {
    width: 20px;
    height: 18px;
    display: inline-block;
    position: relative;
    > .inner
    {
      display: block;
      top: 50%;
      margin-top: -2px;
      width: 19px;
      height: 1px;
      background-color: #FFF;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;

      &:before, &:after
      {
        content: "";
        display: block;
        width: 19px;
        height: 1px;
        background-color: #FFF;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }

      &:before
      {
        top: -6px;
      }
      &:after
      {
        bottom: -6px;
      }
    }  
  }

  &.hamburger-animation
  {
    > .box
    {
      > .inner
      {
        transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
        
        &:before
        {
          transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        } 
        &:after
        {
          transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
      }
    }

    &.active
    {
      > .box
      {
        > .inner
        {
          transform: rotate(90deg);
          background-color: transparent;
          transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;

          &:before
          {
            top: 0;
            transform: rotate(-45deg);
            transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
          &:after
          {
            bottom: 0;
            transform: rotate(45deg);
            transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }
      }
    }
  }
}