.header-page
{
	background-color: #FFF;
	.open-mobile-menu-holder
	{
		float: right;
		position: relative;
		top: 49px;
	}
}

.information-holder
{
	clear: both;
	margin-bottom: 15px;
	.information-basic
	{
		padding-right: 25px;
		float: left;
		.avatar-user
		{
			float: left;
			.avatar
			{
				display: inline-block;
				.avatar-img {
					width: 51px;
					-webkit-border-radius: 30px;
					-moz-border-radius: 30px;
					border-radius: 30px;
				}
			}
		}
		.information-user
		{
			float: left;
			margin-top: 5px;
			margin-left: 15px;
			.username
			{
				font-weight: bold;
				font-size: 14px;
				margin: 0;
				float: none;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				line-height: 14px;
				a
				{
					color: #161616;
				}
			}
			.level
			{
				color: #DA0020;
				font-size: 12px;
				font-weight: bold;
				.level-emblem
				{
					background: none;
					&.rubi
					{
						background: url('../../images/sprite.png') no-repeat -814px -67px;
						width: 9px;
						height: 9px;
					}
					&.rubi-plus
					{
						background: url('../../images/sprite.png') no-repeat -814px -82px;
						width: 19px;
						height: 9px;
					}
					&.esmeralda
					{
						background: url('../../images/sprite.png') no-repeat -814px -97px;
						width: 9px;
						height: 9px;
					}
					&.esmeralda-plus
					{
						background: url('../../images/sprite.png') no-repeat -814px -112px;
						width: 19px;
						height: 9px;
					}
					&.diamante
					{
						background: url('../../images/sprite.png') no-repeat -814px -127px;
						width: 9px;
						height: 9px;
					}
					&.diamante-duplo
					{
						background: url('../../images/sprite.png') no-repeat -814px -142px;
						width: 19px;
						height: 9px;
					}
					&.diamante-triplo
					{
						background: url('../../images/sprite.png') no-repeat -814px -157px;
						width: 29px;
						height: 9px;
					}
					&.diamante-triplo-red
					{
						background: url('../../images/sprite.png') no-repeat -814px -157px;
						width: 29px;
						height: 9px;
					}
					&.royal
					{
						background: url('../../images/sprite.png') no-repeat -814px -157px;
						width: 29px;
						height: 9px;
					}
					&.imperador
					{
						background: url('../../images/sprite.png') no-repeat -814px -157px;
						width: 29px;
						height: 9px;
					}
					&.diamante-triplo-black
					{
						background: url(../../images/sprite.png) no-repeat -814px -186px;
					    width: 29px;
					    height: 10px;
					}
					&.diamante-triplo-imperial,
					&.diamante-triplo-crown,
					&.diamante-triplo-royal
					{
						background: url('../../images/sprite.png') no-repeat -814px -157px;
						width: 29px;
						height: 9px;
					}
				}
			}
		}
	}

	.logout
	{
		float: right;
	    padding: 5px 0 4px 25px;
	    border-left: 1px solid #DA0020;
		.logout-button
		{
			text-align: center;
			display: inline-block;
			.icon
			{
				background: url('../../images/sprite.png') no-repeat -821px -18px;
				width: 20px;
				height: 23px;
				margin-bottom: 5px;
			}
			font-size: 14px;
			color: #DA0020;
		}
	}
}

@media screen and (min-width: 768px) {
	.header-page
	{
		.top-header
		{
			position: relative;
			height: 180px;
		}
	}

	.information-holder
	{
		clear: none;
		float: right;
		margin-top: 72px;
		margin-bottom: 0;
	}
}