.search-area
{
	&.search-area-mobile
	{
		border-top: 3px solid #DA0020;
		border-bottom: 3px solid #DA0020;
		padding: 15px 0;
		width: 100%;
		position: static;
		.input-search
		{
			margin-right: 10px;
			color: #DA0020;
			border: 0;
			border-bottom: 1px solid #DA0020 !important;
			padding: 5px 10px;
			font-size: 14px;
			width: calc(100% - 57px);
			&::placeholder
			{
				color: #DA0020;
				font-weight: 400;
			}
			&.form-control
			{
				display: inline-block;
			}
		}
		.search-submit
		{
			i
			{
				font-size: 24px;
				color: #DA0020;
				font-weight: bold;
				vertical-align: middle;
			}
		}
	}

	&.search-area-desktop
	{
	    opacity: 0;
	    transition: all .3s ease-in-out;
	    position: absolute;
	    width: 0;
	    top: 0;
	    left: -30px;
	    right: auto;
	    background: #dd0020;
	    padding: 21px 0 20px;
	    align-items: center;
		.input-search
		{
			font-size: 16px;
			color: #FFF;
			border: 0;
			width: 96%;
			max-height: 0px;
			transition: max-height .3s ease-in-out;
			border-bottom: 1px solid #FFF !important;
			background: transparent;
			padding: 0;
			&::placeholder
			{
				color: #FFF;
			}
		}
		&.search-area-active
		{
			opacity: 1;
			width: 100%;
			.input-search
			{
				max-height: 100px;
				padding: 7px 10px;
			}
		}
	}
}