.jobtitle-header
{
    display: flex;
    position: relative;
    margin-bottom: 40px;

    &:before
    {
        content: '';
        display: block;
        width: 19px;
        height: 45px;
        background-image: url('../../images/step-before.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 60px;
        left: -19px;
    }

    .jobtitle-leaf
    {
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../images/leaf-my-jobtitle.svg');
        height: 44px;
        flex: 0 0 45px;
        position: relative;
        top: 6px;

        &.specialist
        {
            background-image: url('../../images/leaf-specialist.svg');
        }

        &.director
        {
            background-image: url('../../images/leaf-director.svg');
        }

        &.manager
        {
            background-image: url('../../images/leaf-manager.svg');
        }

        &.encharge
        {
            background-image: url('../../images/leaf-encharge.svg');
        }

        &.supervisor
        {
            background-image: url('../../images/leaf-supervisor.svg');
        }
    }

    .header-info
    {
        .title
        {
            font-size: 22px;
            line-height: 30px;
            font-weight: 400;
            margin: 0 0 10px;
        }

        .cbo
        {
            font-size: 10px;
        }
    }

    @media screen
    {
        @media (min-width: 410px)
        {
            &:before
            {
                left: -17px;
            }
        }

        @media (min-width: $screen-sm)
        {
            &:before
            {
                left: -12px;
            }
        }

        @media (min-width: $screen-md)
        {
            &:before
            {
                left: -17px;
            }
        }
    }
}

.description-text-holder
{
    &:not(:last-child)
    {
        margin-bottom: 15px;
    }

    &.description-list-holder
    {
        margin-bottom: 30px;

        .title
        {
            margin: 0 0 5px;

            .descr-icon
            {
                display: inline-block;
                margin-right: 8px;

                i
                {
                    font-size: 18px;
                    position: relative;
                    top: 2px;
                }
            }
        }

        .list-holder
        {
            .item-list
            {
                display: flex;

                .leaf-before
                {
                    flex: 0 0 12px;
                    height: 12px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url('../../images/leaf-my-jobtitle.svg');
                    position: relative;
                    top: 5px;
                    margin-right: 10px;

                    &.specialist
                    {
                        background-image: url('../../images/leaf-specialist.svg');
                    }

                    &.director
                    {
                        background-image: url('../../images/leaf-director.svg');
                    }

                    &.manager
                    {
                        background-image: url('../../images/leaf-manager.svg');
                    }

                    &.encharge
                    {
                        background-image: url('../../images/leaf-encharge.svg');
                    }

                    &.supervisor
                    {
                        background-image: url('../../images/leaf-supervisor.svg');
                    }
                }
                
                .text
                {
                    margin-bottom: 0px;

                    .level
                    {
                        display: block;
                        font-weight: 700;
                    }
                }

                &:not(:last-child)
                {
                    margin-bottom: 20px;
                }

                &.item-list-check
                {
                    .before-item
                    {
                        flex: 0 0 16px;
                        height: 14px;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-image: url('../../images/item-checked.svg');
                        position: relative;
                        top: 4px;
                        margin-right: 7px;
                        opacity: 0;
                    }

                    .text
                    {
                        color: #C7C7C7;
                    }

                    &.competenceChecked
                    {
                        .before-item
                        {
                            opacity: 1;
                        }

                        .text
                        {
                            color: #888;
                        }
                    }
                }

                &.item-list-jobtitles
                {
                    width: 100%;

                    .info-jobtitle
                    {
                        span
                        {
                            display: block;
                            color: #666;

                            &.name
                            {
                                font-size: 16px;
                            }

                            &.level
                            {
                                font-size: 12px;
                                font-weight: 700;
                                line-height: 22px;
                            }

                            &.see-more
                            {
                                font-weight: 300;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.relational-jobtitles
    {
        border-top: 1px dashed #888;
        padding-top: 30px;

        .list-holder
        {
            .item-list
            {
                .leaf-before
                {
                    flex: 0 0 27px;
                    height: 27px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i
                    {
                        color: #FFF;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}