.career-content-holder
{
    padding-top: 0px !important;
    height: 100vh;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 25%, rgba(235,229,208,1) 100%);
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 25%,rgba(235,229,208,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,1) 25%,rgba(235,229,208,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ebe5d0',GradientType=0 );

    .video-tutorial
    {
        height: 100%;
        width: 100%;
        background-color: #eee6d8;
        z-index: -1;
        opacity: 0;
        transition: all .3s ease-in-out;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: right;

        .close-tutorial
        {
            font-size: 14px;
            text-transform: uppercase;
            display: inline-block;
            padding: 8px 16px;
            border-radius: 30px;
            border: 1px solid #544334;
            color: #FFF;
            background-color: #544334;
            margin: 8px 8px 0px 0;
            cursor: pointer;
        }

        iframe
        {
            height: 100vh !important;
            width: 100% !important;
            max-height: 80%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }

        &.show-tutorial
        {
            z-index: 18;
            opacity: 1;
        }
    }

    > div
    {
        height: 100%;
    }

    .video-loading
    {
        > .video-bg
        {
            @media screen
            {
                @media (max-width: 767px)
                {
                    transform: scale(0.8);
                }
            }
        }
    }

    @import "typography";
    @import "button";
    @import "header";
    @import "sidebar";
    @import "loading";
    @import "content";
    @import "steps";
    @import "jobtitle";
    @import "modal";
}